import { DataTable } from '@components/DataTable';
import { UnstyledLink } from '@components/UnstyledLink';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Download } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { DocumentTemplatesQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { DocumentTemplateActions } from './DocumentTemplateActions';

type Document = DocumentTemplatesQuery['documentTemplates'][number];

interface Props {
  documents: Document[];
  isFetching: boolean;
}

export const DocumentTemplatesTable: React.FC<Props> = ({ documents, isFetching }) => {
  const { formatMessage } = useIntl();

  const columns: GridColDef<Document>[] = [
    {
      field: 'name',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Name' }),
      valueGetter: ({ row }) => row.name,
    },
    {
      field: 'createdBy',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Created by' }),
      valueGetter: ({ row }) => row.createdBy?.fullName,
      renderCell: ({ value }) => <Chip avatar={<UserAvatar displayName={value} />} label={value} />
    },
    {
      field: 'document',
      minWidth: 200,
      headerName: formatMessage({ id: 'Document' }),
      flex: 1,
      valueGetter: ({ row }) => row.fileName,
      renderCell: ({ row }) => (
        <UnstyledLink target="_blank" rel="noreferrer" to={`/${getTenantIdentifier()}/api/DocumentTemplates/${row.id}`}>
          <Chip
            label={row.fileName}
            clickable
            icon={<Download />}
          />
        </UnstyledLink>
      )
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      maxWidth: 64,
      renderCell: ({ row }) => <DocumentTemplateActions template={row} />
    }
  ];

  return (
    <DataTable
      disableColumnMenu
      loading={isFetching}
      columns={columns}
      rows={documents ?? []}
      noSearchResultsMessage={formatMessage({ id: 'No templates found' })}
      noDataMessage={formatMessage({ id: 'No templates have been created yet' })}
    />
  );
};