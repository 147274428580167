import { PageContainer } from '@components/Layout/PageContainer';
import { PageTitle } from '@components/Layout/PageTitle';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { Add, DocumentScanner } from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { DocumentTemplateFilterInput, useDocumentTemplatesQuery } from 'gql/index';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { DocumentTemplateDrawer } from '../components/DocumentTemplateDrawer';
import { DocumentTemplatesTable } from '../components/DocumentTemplatesTable';

export const DocumentTemplatesPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { me } = useGetMe();

  const [selectedTab, setSelectedTab] = useState<'mine' | 'sharedWithMe'>('mine');

  const filter = useMemo((): DocumentTemplateFilterInput => {
    if (selectedTab == 'mine') {
      return {
        createdById: {
          eq: me?.id
        }
      };
    } else {
      return {
        createdById: {
          neq: me?.id
        }
      };
    }
  }, [me?.id, selectedTab]);

  const { data: templates, isFetching } = useDocumentTemplatesQuery({ filter }, { select: d => d.documentTemplates });

  const [isAdding, setIsAdding] = useState(false);

  return (
    <>
      <PageTitle
        backgroundColor="secondary"
        title={formatMessage({ id: 'Document templates' })}
        icon={<DocumentScanner />}
        actionButton={(
          <ResponsiveButton icon={<Add />} variant='contained' color='primary' onClick={() => setIsAdding(true)}>
            {formatMessage({ id: 'Add template' })}
          </ResponsiveButton>
        )}
      />

      <PageContainer>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
            <Tab value='mine' label={formatMessage({ id: 'Mine' })} />
            <Tab value='sharedWithMe' label={formatMessage({ id: 'Shared with me' })} />
          </Tabs>
        </Box>

        <DocumentTemplatesTable
          documents={templates ?? []}
          isFetching={isFetching}
        />
      </PageContainer>

      <DocumentTemplateDrawer
        open={isAdding}
        onClose={() => setIsAdding(false)}
      />
    </>
  );
};