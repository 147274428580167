import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { Delete, Draw, Share } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { DocumentTemplateFragmentFragment, ShareableResourceType, useDeleteDocumentTemplateMutation, useDocumentTemplatesQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DocumentTemplateDrawer } from './DocumentTemplateDrawer';

interface Props {
  template: DocumentTemplateFragmentFragment;
}

export const DocumentTemplateActions: React.FC<Props> = ({ template }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { me } = useGetMe();

  const [permissionsDialogOpen, setPermissionsDialogDialogOpen] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const canEdit = template.createdBy?.id === me?.id;

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);

  const { mutate: deleteDocumentTemplate, isLoading } = useDeleteDocumentTemplateMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Document template deleted successfully' }));
      invalidateQuery(useDocumentTemplatesQuery);
      setConfirmRemoveDialogOpen(false);
    }
  });

  const onDeleteConfirm = () => {
    deleteDocumentTemplate({ input: { id: template.id } });
  };

  if (!canEdit) {
    return null;
  }

  return <>
    <ActionMenu>
      <MenuItem onClick={() => setPermissionsDialogDialogOpen(true)}>
        <ListItemIcon>
          <Share />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({ id: 'Share' })}
        />
      </MenuItem>

      <MenuItem onClick={() => setIsEditing(true)}>
        <ListItemIcon>
          <Draw />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({ id: 'Edit' })}
        />
      </MenuItem>

      <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({ id: 'Delete' })}
        />
      </MenuItem>
    </ActionMenu>

    <PermissionsModal
      open={permissionsDialogOpen}
      onClose={() => setPermissionsDialogDialogOpen(false)}
      resource={{
        resourceType: ShareableResourceType.DocumentTemplate,
        ...template
      }}
    />

    <DocumentTemplateDrawer
      template={template}
      open={isEditing}
      onClose={() => setIsEditing(false)}
    />

    <ConfirmDialog
      open={confirmRemoveDialogOpen}
      title={formatMessage({ id: 'Delete document template' })}
      content={formatMessage({ id: 'This document template will be deleted. Documents generated from it will remain. Are you sure?' })}
      confirmText={formatMessage({ id: 'Delete' })}
      confirmColor='error'
      onCancel={() => setConfirmRemoveDialogOpen(false)}
      onConfirm={onDeleteConfirm}
      loading={isLoading}
    />
  </>;
};