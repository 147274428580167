import { PrincipalType } from 'gql/index';
import typia, { tags } from 'typia';



export const validateWorkflowPrincipal = typia.createValidate<IWorkflowPrincipal>();

export interface IWorkflowPrincipal {
    id: string & tags.Format<'uuid'>,
    principalType: PrincipalType;
}

export interface IDocumentSignatureRequest {
    documentId: number,
    signatory: IWorkflowPrincipal;
}


export enum FormType {
    Organization = 'Organization',
    Project = 'Project'
}
export interface IFormTemplateVariableValue {
    formTemplateId: number
    formType: FormType;
}
