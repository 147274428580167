import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useNotification } from '@utils/useNotification';
import { useCallback } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

type RequestSender = (name: string, description: string, callback: () => void) => void;

interface Props {
  disabled: boolean;
  onFileUploadReady?: (sender: RequestSender, filename: string) => void;
}

export const useDocumentTemplateDropzone = ({ disabled, onFileUploadReady }: Props) => {
  const { notifyFailure } = useNotification();
  const { formatMessage } = useIntl();

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length !== 1) {
      return;
    }

    const file = acceptedFiles[0];

    const extensionIndex = file.name.lastIndexOf('.');
    const hasExtension = extensionIndex > -1;

    const fileName = hasExtension ? file.name.substring(0, extensionIndex) : file.name;
    const fileExtension = hasExtension ? file.name.substring(extensionIndex, file.name.length) : '';

    const formData = new FormData();

    formData.append('File', file);
    formData.append('FileName', fileName + fileExtension);

    const sendRequest: RequestSender = (name: string, description: string, onComplete: () => void) => {
      const request = new XMLHttpRequest();

      formData.append('Name', name);
      formData.append('Description', description);

      request.open('POST', `/${getTenantIdentifier()}/api/DocumentTemplates/Upload`);

      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE) {
          if (request.status === 200) {
            onComplete();
          } else {
            notifyFailure(formatMessage({ id: 'Failed to upload file' }));
          }
        }
      };

      request.send(formData);
    };

    onFileUploadReady?.(sendRequest, fileName + fileExtension);
  }, [formatMessage, notifyFailure, onFileUploadReady]);

  return useDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: false,
    maxSize: 10000000,
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    onDropAccepted,
    onDropRejected: (rejections) => {
      const errors = rejections.flatMap(p => p.errors);
      if (errors.some(p => p.code === ErrorCode.FileInvalidType)) {
        notifyFailure(formatMessage({ id: 'Only .docx files are supported' }));
      }
      if (errors.some(p => p.code === ErrorCode.FileTooLarge)) {
        notifyFailure(formatMessage({ id: 'The selected file is too large.' }));
      }
    },
    disabled: disabled
  });
};