import { ResponsiveButton } from '@components/ResponsiveButton';
import { Alert, AlertTitle, Box, CircularProgress, Stack } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useWorkflowQuery } from 'gql/index';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { WorkflowVariableFormValue } from '../../WorkflowEditor/types';
import { VariableValueInputComponent } from '../../WorkflowEditor/Variables/VariableValueInputComponent';
import { StartWorkflowFormValues } from '../types';

type Props = {
  isWorkflowMisconfigured: boolean;
};

export const ConfigureVariablesStep: React.FC<Props> = ({ isWorkflowMisconfigured }) => {
  const { formatMessage } = useIntl();

  const { setValue, getValues, control } = useFormContext<StartWorkflowFormValues>();

  const { data: template, isLoading } = useWorkflowQuery({ id: getValues('workflowTemplateId') }, { select: p => p.workflowDefinition });

  const { fields } = useFieldArray({ control, name: 'variableInputs' });
  const navigate = useNavigate();

  useEffect(() => {
    setValue('variableInputs', template?.variables
      // filter workflow global variables to only show those being used
      .filter(p => template.allActions.some(action => action.fieldValues.some(fv => fv.value?.id == p.id)))
      .map<WorkflowVariableFormValue>(p => ({
        id: p.id,
        name: p.name ?? '',
        variableValueType: p.variableValueType,
        variableType: p.variableType,
        value: p.serializedValue == null ? null : JSON.parse(p.serializedValue)
      })) ?? []);
  }, [setValue, template]);


  if (isLoading && template == null) {
    return (
      <Stack minHeight='300px' height='100%' width='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  return <Stack gap={4} >
    {isWorkflowMisconfigured && <Alert severity='error'>
      <AlertTitle>{formatMessage({ id: 'This workflow contains configuration errors.' })}</AlertTitle>
      <ResponsiveButton onClick={() => navigate(`/${getTenantIdentifier()}/workflows/edit/${template?.id}`)}>{formatMessage({ id: 'Go to workflow' })}</ResponsiveButton>
    </Alert>}
    {fields.map((p, index) =>
      <Box key={p.id} sx={{ maxWidth: '50ch' }}>
        <Controller
          control={control}
          name={`variableInputs.${index}`}
          rules={{
            validate: variable => (variable.value != null && variable.value != '')
              || formatMessage({ id: 'A value for this variable is required.' })
          }}
          render={({ field, fieldState }) => (
            <VariableValueInputComponent
              variableType={p.variableValueType}
              required
              error={fieldState.error?.message}
              label={p.name ?? ''}
              value={field.value}
              onChange={(newValue) => { field.onChange({ ...field.value, value: newValue.value }); }}
            />
          )}
        />
      </Box>
    )}
  </Stack>;
};