import { GroupedList } from '@components/Grouped/GroupedList';
import { Button, CircularProgress, DialogContent, ListItemButton, Radio, Stack, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useCreateFormTemplateMutation, useFormTemplatesQuery } from 'gql/index';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { defaultFormNames } from '../FormEditor/messages';
import { AddFormForm } from './AddFormDialog';


export const TemplateSelectionStep = () => {
  const { formatMessage } = useIntl();
  const { me } = useGetMe();
  const { setValue, getValues, control, register } = useFormContext<AddFormForm>();
  const { data: templates, isLoading } = useFormTemplatesQuery({}, { select: p => p.formTemplates });
  const formSource = useWatch({ control, name: 'formSource' });
  const selectedTemplateId = useWatch({ control, name: 'selectedTemplateId' });
  const invalidateQuery = useQueryInvalidator();
  const navigate = useNavigate();

  const { mutate: createFormTemplate } = useCreateFormTemplateMutation();

  const handleCreateNewTemplete = () => {
    createFormTemplate({
      input: {
        name: formatMessage(defaultFormNames.template)
      }
    }, {
      onSuccess: d => {
        navigate(`/${getTenantIdentifier()}/templates/edit/${d.createFormTemplate.formTemplate?.id}`);
        invalidateQuery(useFormTemplatesQuery);
      }
    });
  };

  register('selectedTemplateId', {
    validate: (value) => (formSource === 'template' && value == null) ? formatMessage({ id: 'Please select a template.' }) : undefined
  });

  if (isLoading) {
    return (
      <DialogContent>
        <Stack minHeight={400} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Stack>
      </DialogContent>
    );
  }

  return (
    <DialogContent>
      <Stack pt={2} px={2} gap={4} alignItems={'center'} flex={1}>
        <Typography variant='h4'>{formatMessage({ id: 'How would you like to create your form?' })}</Typography>

        <Stack gap={2} minWidth={500}>
          <ListItemButton sx={{ border: '1px solid', borderColor: 'divider' }} onClick={() => {
            setValue('formSource', 'newForm');
            setValue('selectedTemplateId', undefined);
          }}>
            <Radio disableRipple checked={getValues('formSource') === 'newForm'} />
            {formatMessage({ id: 'Create a new form' })}
          </ListItemButton>

          <Typography>
            {formatMessage({ id: 'Or, use a template:' })}
          </Typography>

          <GroupedList
            items={templates ?? []}
            groups={[
              { id: 'myTemplates', name: formatMessage({ id: 'My templates' }) },
              { id: 'sharedWithMe', name: formatMessage({ id: 'Shared with me' }) },
            ] as const}
            getItemGroup={p => {
              if (p.createdById === me?.id) {
                return 'myTemplates';
              } else {
                return 'sharedWithMe';
              }
            }}
            renderItem={template => (
              <ListItemButton
                selected={selectedTemplateId === template.id}
                onClick={() => {
                  setValue('selectedTemplateId', template.id);
                  setValue('formSource', 'template');
                }}
              >
                <Radio checked={selectedTemplateId === template.id} disableRipple />
                {template.formDefinition?.name}
              </ListItemButton>
            )}
            emptyState={{
              title: formatMessage({ id: 'There are no reusable form templates.' }),
              svgSize: 100,
              callToActionComponent: (
                <Button onClick={handleCreateNewTemplete}>
                  {formatMessage({ id: 'Create new template' })}
                </Button>
              )
            }}
          />
        </Stack>
      </Stack>
    </DialogContent>
  );
};