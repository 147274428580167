import { DataTable, DataTableProps } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { useUserVariableResolver } from '@modules/workflow/hooks/useUserVariableResolver';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getEnumValues } from '@utils/enumUtils';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import dayjs from 'dayjs';
import { WorkFlowStatus, WorkflowVariableValueTypes } from 'gql/index';
import _ from 'lodash';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { WorkflowRow } from '../types';
import { WorkflowStatusChip } from './WorkflowChipStatus';
import { WorkflowInstanceActions } from './WorkflowInstanceActions';

interface Props extends Omit<DataTableProps<WorkflowRow>, 'columns' | 'rows'> {
  workflows: WorkflowRow[];
  showEndDate?: boolean;
}

const commonColumnVariables: Partial<GridColDef> = {
  disableColumnMenu: true,
  disableReorder: true,
  sortable: true,
};

export const WorkflowInstancesTable: React.FC<Props> = ({ workflows, showEndDate, ...dataTableProps }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { projectId } = useCurrentProject();
  const resolveUser = useUserVariableResolver();

  const columns = useMemo(() => {
    const columns: GridColDef<WorkflowRow>[] = [
      {
        ...commonColumnVariables,
        field: 'name',
        flex: 3,
        minWidth: 300,
        headerName: formatMessage({ id: 'Name' }),
        valueGetter: ({ row }) => row.name,
        renderCell: ({ row }) => <Typography
          sx={{ ':hover': { textDecoration: 'underline', cursor: 'pointer' } }}
          onClick={() => navigate(`/${getTenantIdentifier()}/projects/${projectId}/workflows/instances/${row.id}`)}
        >
          {row.name}
        </Typography>
      },
      {
        ...commonColumnVariables,
        field: 'startDate',
        minWidth: 200,
        headerName: formatMessage({ id: 'Start date' }),
        type: 'date',
        valueGetter: ({ row }) => dayjs(row.createdOn).toDate(),
      }
    ];

    if (showEndDate) {
      columns.push({
        ...commonColumnVariables,
        field: 'endDate',
        minWidth: 200,
        headerName: formatMessage({ id: 'End date' }),
        type: 'date',
        valueGetter: ({ row }) => (row.stoppedOn ?? row.completedOn) ? dayjs(row.stoppedOn ?? row.completedOn).toDate() : undefined,
      });
    }

    columns.push({
      ...commonColumnVariables,
      disableColumnMenu: false,
      filterable: true,
      type: 'string',
      field: 'participants',
      minWidth: 200,
      headerName: formatMessage({ id: 'Participants' }),
      renderCell: ({ row }) => _.uniqBy(row.variables
        .filter(p => p.variableValueType == WorkflowVariableValueTypes.User)
        .map(resolveUser)
        .filter(notNullOrUndefined), p => p.id)
        .map(user => <UserAvatar key={user.id} displayName={user.fullName} />)
    });

    columns.push({
      ...commonColumnVariables,
      disableColumnMenu: false,

      filterable: true,
      type: 'singleSelect',
      valueOptions: getEnumValues(WorkFlowStatus),
      field: 'status',
      minWidth: 200,
      headerName: formatMessage({ id: 'Status' }),
      renderCell: ({ row }) => (
        <WorkflowStatusChip workFlowStatus={row.workFlowStatus} />

      )
    });

    columns.push({
      ...commonColumnVariables,
      field: 'actions',
      headerName: '',
      maxWidth: 64,
      renderCell: ({ row }) => (
        <WorkflowInstanceActions projectWorkflow={row} />
      )
    });

    return columns;
  }, [formatMessage, navigate, projectId, resolveUser, showEndDate]);

  return (
    <DataTable<WorkflowRow>
      noDataMessage={formatMessage({ id: 'No workflows found.' })}
      autosizeOnMount
      columns={columns}
      disableColumnPinning
      disableColumnReorder
      disableColumnSelector
      getRowId={p => p.id}
      rows={workflows}
      {...dataTableProps}
    />
  );
};