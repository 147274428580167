import { EditorTopBar } from '@components/Editor/TopBar/EditorTopBar';
import { SavingStatusContextProvider } from '@components/Editor/TopBar/SavingStatusContext';
import { TemplateTitle } from '@components/Editor/TopBar/TemplateTitle';
import { CircularProgress, Stack } from '@mui/material';
import { useWorkflowQuery } from 'gql/index';
import { useParams } from 'react-router-dom';
import { WorkflowEditor } from '../components/WorkflowEditor/WorkflowEditor';

export const EditWorkflowPage: React.FC = () => {
  const { workflowId } = useParams();

  const { data: template, isFetching } = useWorkflowQuery({ id: Number(workflowId) || 0 }, {
    cacheTime: 0,
    select: d => d.workflowDefinition
  });


  return !template ? (
    <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
      <CircularProgress />
    </Stack>
  ) : <>
    <SavingStatusContextProvider isEditorLoading={isFetching}>
      <EditorTopBar
        isLoading={isFetching}
        title={<TemplateTitle title={template?.name} />}
      />

      <WorkflowEditor
        isLoading={isFetching}
        definition={template}
      />
    </SavingStatusContextProvider>
  </>;
};