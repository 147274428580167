import { DataTable, DataTableProps } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Stack, Typography } from '@mui/material';
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { WorkflowVariableFragmentFragment, WorkflowVariableValueTypes, useEditWorkflowVariableMutation, useWorkflowQuery, } from 'gql/index';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { WorkflowRoleActions } from './WorkflowRoleActions';

type WorkflowRole = WorkflowVariableFragmentFragment;

interface Props extends Omit<DataTableProps<WorkflowRole>, 'columns' | 'rows'> {
  roles: WorkflowRole[];
  disabled?: boolean;
}

export const WorkflowRolesTable: React.FC<Props> = ({ roles: _roles, disabled, ...dataTableProps }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();

  const roles = _roles.filter(role => role.variableValueType === WorkflowVariableValueTypes.User);

  const dataGridRef = useGridApiRef();

  const columns = useMemo(() => {
    const columns: GridColDef<WorkflowRole>[] = [
      {
        field: 'name',
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        editable: true,
        renderHeader: () => <Typography fontWeight={700} ml={2}>{formatMessage({ id: 'Role name' })}</Typography>,
        renderCell: ({ row: { name } }) => (
          <Stack direction='row' gap={2} alignItems='center' pl={2}>
            <UserAvatar size='small' displayName={name ?? ''} />
            <Typography>
              {name}
            </Typography>
          </Stack>
        )
      },
    ];

    if (!disabled) {
      columns.push(
        {
          field: 'actions',
          headerName: '',
          disableColumnMenu: true,
          sortable: false,
          editable: false,
          width: 32,
          renderCell: ({ row: { id } }) => (
            <WorkflowRoleActions
              id={id}
              onEditClicked={() => dataGridRef.current.startRowEditMode({ id, fieldToFocus: 'name' })}
            />
          )
        });
    }

    return columns;
  }, [dataGridRef, disabled, formatMessage]);

  const { mutateAsync: editWorkflowVariable, isLoading } = useEditWorkflowVariableMutation({
    onSuccess: () => {
      invalidateQuery(useWorkflowQuery);
    }
  });

  const processRowUpdate: DataTableProps<WorkflowRole>['processRowUpdate'] = async (newRow, oldRow) => {
    if (!newRow.name?.trim()) return oldRow;

    try {
      await editWorkflowVariable({
        input: {
          workflowVariableId: newRow.id,
          name: newRow.name
        }
      });
    } catch (e) {
      return oldRow;
    }

    return newRow;
  };

  return (
    <DataTable

      loading={isLoading}
      apiRef={dataGridRef}
      columns={columns}
      rows={roles}
      columnHeaderHeight={48}
      editMode='row'
      showColumnVerticalBorder={false}
      isCellEditable={() => !disabled}
      processRowUpdate={processRowUpdate}
      noDataMessage={formatMessage({ id: 'No roles yet' })}
      onCellClick={cell => {
        if (cell.colDef.field === 'actions') return;
        dataGridRef.current.startRowEditMode({ fieldToFocus: cell.colDef.field, id: cell.row.id });
      }}
      {...dataTableProps}
    />
  );
};