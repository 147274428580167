import { EmptyState } from '@components/EmptyState';
import { ExpandableSearchBar } from '@components/ExpandableSearchBar';
import { PageContainer } from '@components/Layout/PageContainer';
import { PageTitle } from '@components/Layout/PageTitle';
import { GoogleLoginButton, MicrosoftLoginButton } from '@components/LoginButtons';
import { PageSection } from '@components/PageSection/PageSection';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { currentThemeNameState } from '@modules/application/atoms';
import { BrandingFormValues } from '@modules/branding/types';
import { ProjectCard, ProjectCardFragment } from '@modules/projects/components/ProjectCard';
import { Add, DarkMode, LightMode, Menu, TaskAlt, Web } from '@mui/icons-material';
import { AppBar, Button, Grid, Paper, Stack, ThemeProvider, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from '@mui/material';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { useResponsive } from '@utils/useResponsive';
import { useTenantThemeQuery } from 'gql/index';
import { merge } from 'lodash';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { unstable_usePrompt } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { commonThemeOptions, darkTheme, lightTheme } from 'src/themes';
import { EmailPreview } from './EmailPreview';

type Props = {
  lightLogoPreviewUrl: string | undefined,
  darkLogoPreviewUrl: string | undefined,
  loginPageBackgroundPreviewUrl: string | undefined,
  lightSquareLogoPreviewUrl: string | undefined,
  darkSquareLogoPreviewUrl: string | undefined,
  enableCustomBranding: () => void,
}

export const BrandingPreview: React.FC<Props> = ({ lightLogoPreviewUrl, darkLogoPreviewUrl, loginPageBackgroundPreviewUrl, darkSquareLogoPreviewUrl, lightSquareLogoPreviewUrl, enableCustomBranding }) => {
  const { formatMessage } = useIntl();
  const { control, formState } = useFormContext<BrandingFormValues>();
  const [preferredThemeName, _] = useRecoilState(currentThemeNameState);

  const isWhiteLabelEnabled = useWatch({ control: control, name: 'isBrandingEnabled' });
  const primaryColor = useWatch({ control: control, name: 'primaryColor' });
  const secondaryColor = useWatch({ control: control, name: 'secondaryColor' });
  const [previewedTheme, setPreviewedTheme] = useState<'light' | 'dark'>(preferredThemeName == 'light' ? 'light' : 'dark');
  const [previewedItem, setPreviewedItem] = useState<'home' | 'projects' | 'login' | 'email'>('home');
  const { data: tenantTheme } = useTenantThemeQuery({}, { select: p => p.tenantTheme });
  const { isMobile } = useResponsive();

  const baseTheme = (previewedTheme === 'light' ? lightTheme : darkTheme);
  const customTheme: ThemeOptions = {
    ...baseTheme,
    palette: {
      ...baseTheme.palette,
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      }
    }
  };

  const theme = createTheme(merge({}, commonThemeOptions, customTheme));

  unstable_usePrompt({
    message: formatMessage({ id: 'You have unsaved changes. Are you sure you want to leave?' }),
    when: formState.isDirty
  });

  const lightLogoUrl = lightLogoPreviewUrl ?? tenantTheme?.topBarLogoLightUrl;
  const darkLogoUrl = darkLogoPreviewUrl ?? tenantTheme?.topBarLogoDarkUrl;
  const loginBackgroundUrl = loginPageBackgroundPreviewUrl ?? tenantTheme?.loginBackgroundUrl;
  const lightSquareLogoUrl = lightSquareLogoPreviewUrl ?? tenantTheme?.squareLogoLightUrl;
  const darkSquareLogoUrl = darkSquareLogoPreviewUrl ?? tenantTheme?.squareLogoDarkUrl;

  const sampleProjects: ProjectCardFragment[] = [{
    id: 1,
    name: formatMessage({ id: 'Project' }) + 'A',
    isArchived: false,
    createdOn: new Date().toISOString(),
    isTemplate: false,
    description: '',
    securityGroups: [{
      id: 'a',
      name: 'admins',
      members: [{
        id: 'a',
        fullName: 'Default User'
      }]
    }]

  },
  {
    id: 2,
    name: formatMessage({ id: 'Project' }) + 'B',
    isArchived: false,
    createdOn: new Date().toISOString(),
    isTemplate: false,
    description: '',
    securityGroups: [{
      id: 'a',
      name: 'admins',
      members: [{
        id: 'a',
        fullName: 'Default User'
      }]
    }]

  }];

  return <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', p: 2, gap: 4 }}>
    <Typography variant='h6'>{formatMessage({ id: 'Preview' })}</Typography>

    <ToggleButtonGroup color="primary" exclusive fullWidth>
      <ToggleButton
        value={'light'}
        selected={previewedTheme === 'light'}
        onClick={(_, value) => setPreviewedTheme(value)}
      >
        <LightMode />
        <Typography>{formatMessage({ id: 'Light' })}</Typography>
      </ToggleButton>
      <ToggleButton
        value={'dark'}
        selected={previewedTheme === 'dark'}
        onClick={(_, value) => setPreviewedTheme(value)}
      >
        <DarkMode />
        <Typography>{formatMessage({ id: 'Dark' })}</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
    <ToggleButtonGroup color="primary" exclusive fullWidth>
      <ToggleButton
        value={'home'}
        selected={previewedItem === 'home'}
        onClick={(_, value) => setPreviewedItem(value)}
      >
        <Typography>{formatMessage({ id: 'Project home' })}</Typography>
      </ToggleButton>
      <ToggleButton
        value={'projects'}
        selected={previewedItem === 'projects'}
        onClick={(_, value) => setPreviewedItem(value)}
      >
        <Typography>{formatMessage({ id: 'Projects' })}</Typography>
      </ToggleButton>
      <ToggleButton
        value={'login'}
        selected={previewedItem === 'login'}
        onClick={(_, value) => setPreviewedItem(value)}
      >
        <Typography>{formatMessage({ id: 'Login' })}</Typography>
      </ToggleButton>
      <ToggleButton
        value={'email'}
        selected={previewedItem === 'email'}
        onClick={(_, value) => setPreviewedItem(value)}
      >
        <Typography>{formatMessage({ id: 'Email' })}</Typography>
      </ToggleButton>
    </ToggleButtonGroup>

    {!isWhiteLabelEnabled &&
      <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <EmptyState
          hideImage
          title={formatMessage({ id: 'Custom branding is disabled' })}
          subtitle={formatMessage({ id: 'Enable custom branding and make sure to review carefully light mode and dark mode versions of all pages before saving your changes.' })}
          callToActionComponent={<Button variant='contained' onClick={() => enableCustomBranding()}>{formatMessage({ id: 'Enable' })}</Button>}
        />
      </Paper>}

    <ThemeProvider theme={theme}>
      {isWhiteLabelEnabled && previewedItem == 'home' &&

        <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: theme.palette.background.default }}>

          <AppBar position='static' color='default'><Toolbar>
            <Menu style={{ marginRight: '16px' }} />
            {previewedTheme === 'light' && lightLogoUrl && <img src={lightLogoUrl} style={{ height: '32px', width: 'auto' }} />}
            {previewedTheme === 'dark' && darkLogoUrl && <img src={darkLogoUrl} style={{ height: '32px', width: 'auto' }} />}
          </Toolbar></AppBar>

          <PageContainer>
            <PageSection
              title={formatMessage({ id: 'Meetings' })}
              icon={<TaskAlt />}
              emptyState={formatMessage({ id: 'There are no upcoming meetings.' })}
            />
            <PageSection
              title={formatMessage({ id: 'My Tasks' })}
              icon={<TaskAlt />}
              emptyState={formatMessage({ id: 'There are no pending tasks.' })}
            />
            <PageSection
              title={formatMessage({ id: 'Workflows' })}
              icon={<TaskAlt />}
              emptyState={formatMessage({ id: 'There are no workflows in progress.' })}
            />
          </PageContainer>

        </Paper>

      }

      {isWhiteLabelEnabled && previewedItem == 'projects' &&

        <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: theme.palette.background.default }}>

          <AppBar position='static' color='default'><Toolbar>
            <Menu style={{ marginRight: '16px' }} />
            {previewedTheme === 'light' && lightLogoUrl && <img src={lightLogoUrl} style={{ height: '32px', width: 'auto' }} />}
            {previewedTheme === 'dark' && darkLogoUrl && <img src={darkLogoUrl} style={{ height: '32px', width: 'auto' }} />}
          </Toolbar></AppBar>
          <PageTitle icon={<Web />} title={formatMessage({ id: 'Projects' })} backgroundColor='secondary'
            actionButton={<ResponsiveButton color='primary' variant='contained' icon={<Add />} >
              {formatMessage({ id: 'Create project' })}
            </ResponsiveButton>}></PageTitle>
          <PageContainer>
            <Stack direction={isMobile ? 'column' : 'row'} justifyContent={'space-between'} flexWrap='wrap' gap={2} mb={4}>

              <ExpandableSearchBar
                disabled={true}
                fullWidth={isMobile}
                sx={{ width: isMobile ? undefined : '50ch' }}
              />
            </Stack>

            <Grid container spacing={2}>
              {sampleProjects.map(p => (
                <Grid item xs={12} md={3} key={p.id}>
                  <ProjectCard project={p} disabled />
                </Grid>))}

            </Grid>
          </PageContainer>

        </Paper>

      }

      {isWhiteLabelEnabled && previewedItem == 'login' &&

        <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: theme.palette.background.default }}>

          <Stack
            sx={{
              backgroundImage: `url(${loginBackgroundUrl})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              flexGrow: 1
            }}
            flex={1}
            justifyContent={'center'}
            alignItems={'center'}>
            <Paper >
              <Stack gap={4} alignItems='center' p={4}>
                {previewedTheme === 'light' && lightSquareLogoUrl && <img src={lightSquareLogoUrl} style={{ height: '200px', width: '200px' }} />}
                {previewedTheme === 'dark' && darkSquareLogoUrl && <img src={darkSquareLogoUrl} style={{ height: '200px', width: '200px' }} />}
                <Typography variant='h6'>{formatMessage({ id: 'Sign in to client portal' })}</Typography>
                <Stack gap={2}>
                  <MicrosoftLoginButton
                    fullWidth
                  />

                  <GoogleLoginButton
                    fullWidth
                  />
                </Stack>
              </Stack>
            </Paper>
          </Stack>

        </Paper>

      }

      {isWhiteLabelEnabled && previewedItem == 'email' &&
        <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', backgroundColor: theme.palette.background.default, p: 4 }}>
          <EmailPreview logoUrlPreview={previewedTheme == 'dark' ? darkLogoUrl : lightLogoUrl} themeMode={previewedTheme} />
        </Paper>
      }
    </ThemeProvider >
  </Paper >;
};