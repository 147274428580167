import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ImportProjectTextField } from './ImportProjectTextField';
import { TaskImportFormValues } from './types';

export const TaskImportProjectStep: React.FC = () => {
  const { formatMessage } = useIntl();

  const { control, setValue } = useFormContext<TaskImportFormValues>();

  const projectToImportFromId = useWatch({ control, name: 'projectToImportFromId' });
  useEffect(() => {
    setValue('taskIds', []);
    setValue('userMapping', {});
  }, [setValue, projectToImportFromId]);

  return <>
    <Controller
      control={control}
      name='projectToImportFromId'
      rules={{ required: formatMessage({ id: 'A project must be selected' }) }}
      render={({ field, fieldState: { error } }) => (
        <ImportProjectTextField
          value={field.value}
          error={error?.message}
          onChange={field.onChange}
        />
      )}
    />
  </>;
};