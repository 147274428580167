import { library } from '@fortawesome/fontawesome-svg-core';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { AddBoxOutlined, IndeterminateCheckBoxOutlined } from '@mui/icons-material';
import { IconButton, ListItem, ListItemButton, ListItemIcon, Stack, Typography, styled } from '@mui/material';
import { Folder, FolderType, useChildFoldersQuery, useFolderEntriesQuery } from 'gql/index';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFolderName } from '../utils/folderUtils';
import { FolderActions } from './FolderActions';
import { ProjectDocumentsContext } from './ProjectDocumentsContextProvider';

library.add(faFolderOpen);
library.add(faFolder);

type MyFolder = Pick<Folder, 'id' | 'name' | 'parentFolderId' | 'path' | 'folderType'>;

interface Props {
  folder: MyFolder;
  onClick: (folder: MyFolder) => void;
}

const ExpandIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'hide'
})<{
  hide?: boolean;
}>(({ hide }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 12,
  minWidth: '24px',
  fontSize: '14px',
  visibility: hide ? 'hidden' : 'visible'
}));

export const TreeViewFolderItem: React.FC<Props> = ({ folder, ...props }) => {
  const { formatMessage } = useIntl();
  const { getFolderName } = useFolderName();

  const { projectId } = useCurrentProject();

  const { data: childFolders } = useChildFoldersQuery({ parentFolderId: folder.id }, { select: d => d.childFolders });
  const { data: entries } = useFolderEntriesQuery({ projectId, folderId: folder.id }, { select: d => d.folderEntries });

  const { currentFolderId, movingFolder, breadcrumbPath } = useContext(ProjectDocumentsContext);
  const defualtExpanded = breadcrumbPath.includes(folder.id);

  const [expanded, setExpanded] = useState(folder.folderType === FolderType.Root || defualtExpanded);

  const onClick = (folder: MyFolder) => {
    if (currentFolderId === folder.id) {
      setExpanded(!expanded);
    } else {
      props.onClick(folder);
    }
  };

  const onExpandedClicked = () => {
    setExpanded(!expanded);
  };

  const folderIconColor = currentFolderId == folder.id ? 'orange' : undefined;
  const folderIcon = expanded ? faFolderOpen : faFolder;

  return (
    <Stack sx={movingFolder?.id === folder.id ? { backgroundColor: t => t.palette.action.selected } : undefined}>
      <ListItem
        disablePadding
        sx={{
          '&:hover .showOnHover': {
            visibility: 'visible',
          }
        }}
        secondaryAction={(
          <FolderActions className='showOnHover' folder={folder} iconButtonProps={{ sx: { visibility: 'hidden' }, size: 'small' }} />
        )}
      >
        <Stack width='100%'>
          <ListItemButton selected={currentFolderId === folder.id} onClick={() => onClick(folder)} sx={{ pl: 5 }}>
            <ListItemIcon sx={{ minWidth: '32px' }}>
              <FontAwesomeIcon icon={folderIcon} color={folderIconColor} />
            </ListItemIcon>

            <Typography overflow='hidden' textOverflow='ellipsis' noWrap>
              {getFolderName(folder)}
            </Typography>

            {(childFolders?.length === 0 && entries?.length === 0) && (
              <Typography pl={1} fontSize={8} variant='subtitle2' fontStyle='italic'>
                {formatMessage({ id: 'Empty' })}
              </Typography>
            )}
          </ListItemButton>
        </Stack>

        <ExpandIcon size='small' hide={!childFolders || childFolders.length === 0} onClick={onExpandedClicked}>
          {expanded ? (
            <IndeterminateCheckBoxOutlined fontSize='inherit' />
          ) : (
            <AddBoxOutlined fontSize='inherit' />
          )}
        </ExpandIcon>
      </ListItem>

      {expanded && (
        <Stack ml={3} sx={{ borderLeft: t => `1px dotted ${t.palette.action.disabled}` }}>
          {childFolders?.map(childFolder => (
            <TreeViewFolderItem key={childFolder.id} folder={childFolder} onClick={onClick} />
          ))}
        </Stack>
      )}
    </Stack>
  );
};