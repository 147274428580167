import { ReactComponent as EmptyStateSvg } from '@assets/images/undraw_no_data.svg';
import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

export interface EmptyStateProps {
  title?: string;
  subtitle?: string;
  callToActionComponent?: React.ReactNode;
  svgSize?: number;
  hideImage?: boolean;
  image?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const svgStyle = props.svgSize ? { height: props.svgSize, width: 'auto' } : { height: 'auto', width: '100%', maxHeight: 200 };
  const theme = useTheme();
  return (
    <Stack alignItems='center' justifyContent='center' p={3} spacing={5} height={'100%'}>
      {!props.hideImage && props.image ? <props.image fill={theme.palette.primary.main} style={{ ...svgStyle }} />
        : !props.hideImage ? <EmptyStateSvg fill={theme.palette.primary.main} style={{ ...svgStyle }} /> : undefined}

      <Stack>
        <Typography fontWeight={600} variant='h6' textAlign='center'>{props.title}</Typography>
        {props.subtitle && (
          <Typography variant='body1' color='text.secondary' textAlign='center'>{props.subtitle}</Typography>
        )}
      </Stack>

      {props.callToActionComponent}
    </Stack>
  );
};