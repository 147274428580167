import { FormType, IFormTemplateVariableValue } from '@modules/workflow/types';
import { Autocomplete, ListItem, TextField } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useFormTemplatesQuery, useProjectFormOverviewsQuery } from 'gql/index';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';


type Props = {
  selectedTemplate: IFormTemplateVariableValue | undefined;
  onChange: (selectedTemplate?: IFormTemplateVariableValue) => void;
  required?: boolean;
  error?: string;
  disabled?: boolean;
};

type FormTemplateAutocompleteGroup = {
  id: number,
  name: string,
  optionType: 'project' | 'mine' | 'sharedWithMe',
  grouping: string;
}

export const FormTemplateAutocomplete: React.FC<Props> = ({ selectedTemplate, onChange, required, error, disabled }) => {
  const { formatMessage } = useIntl();

  const { projectId: projectIdString } = useParams();
  const isProjectScope = projectIdString != null;
  const { me } = useGetMe();

  const { data: orgTtemplates } = useFormTemplatesQuery(undefined, { select: d => d.formTemplates, staleTime: 120000 });
  const { data: projectTemplates } = useProjectFormOverviewsQuery({ projectId: Number(projectIdString) }, { select: d => d.projectFormOverviews, staleTime: 120000, enabled: isProjectScope });

  const allTemplates: FormTemplateAutocompleteGroup[] = useMemo(() => _.sortBy((orgTtemplates ?? [])
    .map<FormTemplateAutocompleteGroup>(p => ({
      id: p.id,
      name: p.formDefinition?.name ?? '',
      optionType: p.createdById == me?.id ? 'mine' : 'sharedWithMe',
      grouping: p.createdById == me?.id ? formatMessage({ id: 'My templates' }) : formatMessage({ id: 'Shared with me' })
    }))
    .concat((projectTemplates ?? []).map(p => ({
      id: p.id,
      name: p.formName,
      optionType: 'project',
      grouping: formatMessage({ id: 'Available in project' })
    }))), [p => p.optionType == 'project' ? 0 : p.optionType == 'mine' ? 1 : 2]), [formatMessage, me?.id, orgTtemplates, projectTemplates]);

  const value = allTemplates?.find(template => (selectedTemplate?.formType == FormType.Project && template.optionType == 'project' && template.id === selectedTemplate?.formTemplateId)
    || (selectedTemplate?.formType == FormType.Organization && template.optionType != 'project' && template.id === selectedTemplate?.formTemplateId)) ?? null;

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => onChange(newValue == null ? undefined : { formTemplateId: newValue.id, formType: newValue.optionType == 'project' ? FormType.Project : FormType.Organization })}
      options={allTemplates}
      groupBy={p => p.grouping}
      getOptionLabel={template => template.name ?? ''}
      renderOption={(props, option) => <ListItem  {...props} key={`${option.optionType}-${option.id}`}>{option.name}</ListItem>}

      renderInput={params => (
        <TextField
          label={formatMessage({ id: 'Form template' })}
          required={required}
          error={error != null}
          helperText={error}
          {...params}
          disabled={disabled}
        />
      )}
    />
  );
};