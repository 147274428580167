import { ShareableResource } from '@modules/securityGroups/components/Permissions/types';
import { RoleAssignmentFragmentFragment, ShareableResourceType, useDocumentPermissionsQuery, useDocumentTemplatePermissionsQuery, useExternalLinkPermissionsQuery, useFolderPermissionsQuery, useProjectFormPermissionsQuery, useProjectTaskPermissionsQuery, useProjectTemplatePermissionsQuery, useTemplatePermissionsQuery, useWorkflowTemplatePermissionsQuery } from 'gql/index';
import { useParams } from 'react-router-dom';

export const useRoleAssignment = (resource: ShareableResource) => {
  const { projectId: projectIdString } = useParams();
  const projectId = Number(projectIdString);

  const { data: documentPermissions } = useDocumentPermissionsQuery({ projectId, documentId: resource.id }, { select: d => d.documentPermissions, enabled: resource.resourceType === ShareableResourceType.Document });
  const { data: folderPermissions } = useFolderPermissionsQuery({ projectId, folderId: resource.id }, { select: d => d.folderPermissions, enabled: resource.resourceType === ShareableResourceType.Folder });
  const { data: taskPermissions } = useProjectTaskPermissionsQuery({ projectId, taskId: resource.id }, { select: d => d.taskPermissions, enabled: resource.resourceType === ShareableResourceType.Task });
  const { data: formPermissions } = useProjectFormPermissionsQuery({ projectId, formId: resource.id }, { select: d => d.projectFormPermissions, enabled: resource.resourceType === ShareableResourceType.Form });
  const { data: templatePermissions } = useTemplatePermissionsQuery({ formTemplateId: resource.id }, { select: d => d.formTemplatePermissions, enabled: resource.resourceType === ShareableResourceType.FormTemplate });
  const { data: externalLinkPermissions } = useExternalLinkPermissionsQuery({ projectId, linkId: resource.id }, { select: d => d.externalLinkPermissions, enabled: resource.resourceType === ShareableResourceType.ExternalLink });
  const { data: workflowTemplatePermissions } = useWorkflowTemplatePermissionsQuery({ templateId: resource.id }, { select: d => d.workflowTemplatePermissions, enabled: resource.resourceType === ShareableResourceType.WorkflowDefinition });
  const { data: projectTemplatePermissions } = useProjectTemplatePermissionsQuery({ templateId: resource.id }, { select: d => d.projectTemplatePermissions, enabled: resource.resourceType === ShareableResourceType.ProjectTemplate });
  const { data: documentTemplatePermissions } = useDocumentTemplatePermissionsQuery({ id: resource.id }, { select: d => d.documentTemplatePermissions, enabled: resource.resourceType === ShareableResourceType.DocumentTemplate });

  const roleAssignmentGetter: Record<ShareableResourceType, RoleAssignmentFragmentFragment[] | undefined> = {
    [ShareableResourceType.Document]: documentPermissions,
    [ShareableResourceType.Folder]: folderPermissions,
    [ShareableResourceType.Task]: taskPermissions,
    [ShareableResourceType.Form]: formPermissions,
    [ShareableResourceType.FormTemplate]: templatePermissions,
    [ShareableResourceType.ExternalLink]: externalLinkPermissions,
    [ShareableResourceType.WorkflowDefinition]: workflowTemplatePermissions,
    [ShareableResourceType.ProjectTemplate]: projectTemplatePermissions,
    [ShareableResourceType.DocumentTemplate]: documentTemplatePermissions
  };

  const roleAssignments = roleAssignmentGetter[resource.resourceType] ?? [];

  const groupAssignments = roleAssignments.filter(p => p.securityGroup != null);

  const sharedWithAllAssignment = roleAssignments.find(t => t.sharedWithAllProjectMembers === true);

  const userAssignments = roleAssignments.filter(p => p.user != null);

  return { userAssignments, groupAssignments, sharedWithAllAssignment };
};