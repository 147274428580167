import { UnstyledLink } from '@components/UnstyledLink';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { AvatarGroup, Card, CardActionArea, CardMedia, Divider, Stack, Typography } from '@mui/material';
import { GetProjectsQuery, Project } from 'gql/index';
import { useIntl } from 'react-intl';

export type ProjectCardFragment = Pick<Project, 'name' | 'isArchived' | 'createdOn' | 'isTemplate' | 'id' | 'description'> & { securityGroups: GetProjectsQuery['projects'][number]['securityGroups'] };

interface Props {
  project: ProjectCardFragment;
  disabled?: boolean;
}

export const ProjectCard: React.FC<Props> = ({ project, disabled }) => {
  const { formatDate, formatMessage } = useIntl();

  return (
    <Card style={{ width: '100%', height: '200px' }}>
      <UnstyledLink sx={{ pointerEvents: disabled ? 'none' : 'inherit' }} to={project.isTemplate ? `${project.id.toString()}/templateHome` : `${project.id.toString()}/home`}>
        <CardActionArea sx={{ height: '100%' }}>
          <Stack height='100%'>
            <CardMedia component='div' sx={{ height: 24, backgroundColor: project.isArchived ? 'action.disabled' : 'secondary.main' }}>
              {project.isTemplate && <Typography color={theme => theme.palette.getContrastText(theme.palette.secondary.main)} textAlign={'center'}>{formatMessage({ id: 'Project template' })}</Typography>}
            </CardMedia>

            <Stack flexGrow={1} p={1} gap={2} overflow='hidden'>
              <Stack>
                <Typography variant='h6' maxHeight='80px' color={project.isArchived ? 'text.secondary' : 'text.primary'} style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden' }}>
                  {project.name}
                </Typography>

                <Typography variant='subtitle2' color='text.secondary'>{formatDate(project.createdOn)}</Typography>
              </Stack>

              {project.description && (
                <Typography variant='body2' color='text.secondary' overflow='hidden' textOverflow='ellipsis' style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden' }}>
                  {project.description}
                </Typography>
              )}
            </Stack>

            <Divider />

            <Stack alignItems='start' p={1}>
              <AvatarGroup sx={{ '& .MuiAvatar-root': { height: '32px', width: '32px', fontSize: 12 } }}>
                {project.securityGroups.flatMap(p => p.members).map(user => (
                  <UserAvatar key={user.id} displayName={user.fullName} />
                ))}
              </AvatarGroup>
            </Stack>
          </Stack>
        </CardActionArea>
      </UnstyledLink>
    </Card>
  );
};