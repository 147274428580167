import { EditorTitleSection, EditorTitleValues } from '@components/Editor/EditorTitleSection';
import { EditorSavingStatus, SavingStatusContext } from '@components/Editor/TopBar/SavingStatusContext';
import { useEditorHelpers } from '@components/Editor/TopBar/useEditorHelpers';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useEditWorkflowMutation, useProjectWorkflowsQuery, useWorkflowActionDefinitionsQuery, useWorkflowQuery, useWorkflowTemplatesQuery } from 'gql/index';
import { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { WorkflowEditorContext } from './WorkflowEditorContext';
import { WorkflowEditorValues } from './types';

interface Props {
  defaultOpen?: boolean;
}

export const WorkflowTitleSection: React.FC<Props> = ({ defaultOpen }) => {
  const invalidateQuery = useQueryInvalidator();

  const { disabled } = useContext(WorkflowEditorContext);
  const { control, setValue } = useFormContext<WorkflowEditorValues>();
  const { onEditionError, onEditionSuccess } = useEditorHelpers();

  const workflowId = useWatch({ control, name: 'id' });
  const name = useWatch({ control, name: 'name' });
  const description = useWatch({ control, name: 'description' });

  const { editorSavingStatus, isEditorLoading } = useContext(SavingStatusContext);

  const { mutate: editWorkflowContent, isLoading: isEditingWorkflowContent } = useEditWorkflowMutation({ onError: onEditionError, onSuccess: onEditionSuccess });

  const isLoading = editorSavingStatus === EditorSavingStatus.Saving || isEditorLoading || isEditingWorkflowContent;

  const onTitleSectionInactivated = (values: EditorTitleValues) => {
    if (!name.trim()) return;
    setValue('name', values.name);
    setValue('description', values.description);

    editWorkflowContent({
      input: {
        id: workflowId,
        ...values
      }
    }, {
      onSuccess: () => {
        invalidateQuery(useWorkflowTemplatesQuery);
        invalidateQuery(useProjectWorkflowsQuery);
        invalidateQuery(useWorkflowQuery);
        invalidateQuery(useWorkflowActionDefinitionsQuery);
      }
    });
  };

  return (
    <EditorTitleSection
      disabled={disabled}
      defaultOpen={defaultOpen}
      values={{ name, description }}
      isLoading={isLoading}
      onSubmit={onTitleSectionInactivated}
    />
  );
};