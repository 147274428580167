import { ReactComponent as NoProjects } from '@assets/images/undraw_organizing_projects.svg';
import { EmptyState } from '@components/EmptyState';
import { ExpandableSearchBar } from '@components/ExpandableSearchBar';
import { PageContainer } from '@components/Layout/PageContainer';
import { PageTitle } from '@components/Layout/PageTitle';
import { LoadingScreen } from '@components/LoadingScreen';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { Add, InfoSharp, Web } from '@mui/icons-material';
import { Button, Grid, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { deburrSearch } from '@utils/deburrSearch';
import { useGetMe } from '@utils/useGetMe';
import { useResponsive } from '@utils/useResponsive';
import { ProjectFilterInput, useGetProjectsQuery } from 'gql/index';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { AddProjectDialog } from '../components/AddProjectDialog/AddProjectDialog';
import { AddProjectTemplateDialog } from '../components/AddProjectTemplateDialog';
import { ProjectCard } from '../components/ProjectCard';
import { NoProjectsCustomerView } from './NoProjectsCustomerView';

export const ProjectsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();
  const { me, canViewProjectsList, canCreateProject, hasTenantAdminRole } = useGetMe();
  const [selectedTab, setSelectedTab] = useState('mine');

  const filter = useMemo(() => {
    const filters: ProjectFilterInput = {
      and: [
        { isArchived: { eq: selectedTab == 'archived' } },
        { isTemplate: { eq: selectedTab == 'templates' } }
      ]
    };

    if (selectedTab === 'mine' && me?.id) {
      filters.and?.push({
        securityGroups: {
          some: {
            members: {
              some: {
                id: {
                  eq: me?.id
                }
              }
            }
          }
        }
      });
    }

    if (selectedTab === 'all' && me?.id) {
      filters.and?.push({
        securityGroups: {
          none: {
            members: {
              some: {
                id: {
                  eq: me?.id
                }
              }
            }
          }
        }
      });
    }

    if (selectedTab === 'templates') {
      filters.and?.push({
        createdById: { eq: me?.id }
      });
    }

    return filters;
  }, [me?.id, selectedTab]);

  const [searchFilter, setSearchFilter] = useState('');

  const { data: projects, isFetching: isFetchingProjects } = useGetProjectsQuery({ filter }, { keepPreviousData: true, select: d => d.projects });

  const { data: sharedProjectTemplates } = useGetProjectsQuery({
    filter: {
      and: [
        { isTemplate: { eq: true } },
        { createdById: { neq: me?.id } }
      ]
    }
  }, { keepPreviousData: true, select: d => d.projects });

  const filteredProjects = projects?.filter(p => deburrSearch(p.name, searchFilter)) ?? [];
  const filteredSharedProjectTemplates = sharedProjectTemplates?.filter(p => deburrSearch(p.name, searchFilter)) ?? [];

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isAddTemplateDialogOpen, setIsAddTemplateDialogOpen] = useState(false);

  const hasSearchResults = (filteredProjects.length != 0 || (filteredSharedProjectTemplates.length != 0 && selectedTab == 'templates')) && searchFilter != '';

  if (projects && canViewProjectsList === false) {
    if (projects.length > 0) {
      return <Navigate to={`${projects[0].id}/home`} />;
    } else {
      return <NoProjectsCustomerView />;
    }
  }
  else if (projects == null) {
    return <LoadingScreen />;
  }

  return <>
    <PageTitle
      title={formatMessage({ id: 'Projects' })}
      icon={<Web />}
      actionButton={canCreateProject && (selectedTab === 'templates' ?
        <ResponsiveButton color='primary' variant='contained' icon={<Add />} onClick={() => setIsAddTemplateDialogOpen(true)}>
          {formatMessage({ id: 'Create template' })}
        </ResponsiveButton>
        : <ResponsiveButton color='primary' variant='contained' icon={<Add />} onClick={() => setIsAddDialogOpen(true)}>
          {formatMessage({ id: 'Create project' })}
        </ResponsiveButton>

      )}
      loading={isFetchingProjects}
      backgroundColor='secondary'
    />

    <AddProjectDialog open={isAddDialogOpen} onClose={() => setIsAddDialogOpen(false)} onAddTemplateRequested={() => setIsAddTemplateDialogOpen(true)} />
    <AddProjectTemplateDialog open={isAddTemplateDialogOpen} onClose={() => setIsAddTemplateDialogOpen(false)} />

    <PageContainer>
      <Stack direction={isMobile ? 'column' : 'row'} justifyContent={'space-between'} flexWrap='wrap' gap={2} mb={4}>
        <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)} textColor={'primary'} indicatorColor='primary'>
          <Tab value='mine' label={formatMessage({ id: 'My projects' })} />
          {canCreateProject && <Tab value='templates' label={formatMessage({ id: 'Templates' })}
            icon={<Tooltip title={formatMessage({ id: 'Setup project templates to quickly start new projects with default content.' })}><InfoSharp fontSize={'small'} /></Tooltip>}
            iconPosition='end' />}
          {hasTenantAdminRole && <Tab value='all' label={formatMessage({ id: 'All projects' })} />}
          <Tab value='archived' label={formatMessage({ id: 'Archived' })} />

        </Tabs>

        <ExpandableSearchBar

          fullWidth={isMobile}
          sx={{ width: isMobile ? undefined : '50ch' }}
          value={searchFilter}
          onChange={e => setSearchFilter(e.target.value)}

        />
      </Stack>

      {selectedTab === 'templates' && sharedProjectTemplates?.length !== 0 && (
        <Typography variant='h6' mb={2}>
          {formatMessage({ id: 'My templates' })}
        </Typography>
      )}

      <Grid container spacing={2}>
        {filteredProjects?.map(p => (
          <Grid item xs={12} md={3} key={p.id}>
            <ProjectCard project={p} />
          </Grid>
        ))}

        {filteredProjects?.length === 0 && canCreateProject && selectedTab === 'mine' && searchFilter == '' && (
          <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
            <EmptyState
              image={NoProjects}
              title={formatMessage({ id: 'Let\'s create your first project!' })}
              subtitle={formatMessage({ id: 'Choose an option below to begin.' })}
              callToActionComponent={(
                <Stack direction={'row'} spacing={4}>
                  <Button variant='contained' startIcon={(<Add />)} onClick={() => setIsAddDialogOpen(true)}>
                    {formatMessage({ id: 'Create project' })}
                  </Button>
                </Stack>
              )}
            />
          </Grid>
        )}

        {filteredProjects?.length === 0 && !canCreateProject && selectedTab === 'mine' && searchFilter == '' && (
          <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
            <EmptyState
              image={NoProjects}
              title={formatMessage({ id: 'You have not yet been assigned to any project.' })}
              subtitle={formatMessage({ id: 'Projects will be displayed once an administrator has assigned you to a project.' })}
            />
          </Grid>
        )}

        {!hasSearchResults && selectedTab != 'templates' && searchFilter != '' && (
          <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
            <EmptyState

              title={formatMessage({ id: 'There are no projects matching the selected view and search filter.' })}
            />
          </Grid>
        )}

        {filteredProjects?.length === 0 && selectedTab == 'templates' && (
          <Grid item xs={12} display='flex' alignItems='center' justifyContent='start'>
            <EmptyState
              hideImage
              subtitle={formatMessage({ id: 'There are no templates matching the selected view and search filter.' })}
            />
          </Grid>
        )}

      </Grid>

      {selectedTab === 'templates' && sharedProjectTemplates?.length !== 0 && <>
        <Typography variant='h6' mt={4} mb={2}>
          {formatMessage({ id: 'Shared with me' })}
        </Typography>

        <Grid container spacing={2}>
          {filteredSharedProjectTemplates?.map(p => (
            <Grid item xs={12} md={3} key={p.id}>
              <ProjectCard project={p} />
            </Grid>
          ))}

        </Grid>

      </>}
    </PageContainer>
  </>;
};