import { DocumentTemplate, ExternalLink, WorkflowDefinition } from 'gql/subscriptions';
import { Document, Folder, FormTemplate, PermissionsGrouping, ProjectForm, ProjectTask, ShareableResourceType } from '../../../../gql';


export interface ShareableDocument extends Pick<Document, 'id' | 'fileName' | 'publicSlug'> {
  resourceType: ShareableResourceType.Document;
}

export interface ShareableFolder extends Pick<Folder, 'id' | 'name' | 'folderType'> {
  resourceType: ShareableResourceType.Folder;
}

export interface ShareableTask extends Pick<ProjectTask, 'id' | 'name'> {
  resourceType: ShareableResourceType.Task;
}

export interface ShareableForm extends Pick<ProjectForm, 'id'> {
  name: string,
  resourceType: ShareableResourceType.Form;
}

export interface ShareableFormTemplate extends Pick<FormTemplate, 'id'> {
  name: string,
  resourceType: ShareableResourceType.FormTemplate;
}

export interface ShareableExternalLink extends Pick<ExternalLink, 'id' | 'name' | 'link'> {
  resourceType: ShareableResourceType.ExternalLink;
}

export interface ShareableWorkflowDefinition extends Pick<WorkflowDefinition, 'id' | 'name' | 'projectId'> {
  resourceType: ShareableResourceType.WorkflowDefinition;
}

export interface ShareableProjectTemplate extends Pick<WorkflowDefinition, 'id' | 'name'> {
  resourceType: ShareableResourceType.ProjectTemplate;
}

export interface ShareableDocumentTemplate extends Pick<DocumentTemplate, 'id' | 'name'> {
  resourceType: ShareableResourceType.DocumentTemplate;
}

export type ShareableResource = ShareableDocument
  | ShareableFolder
  | ShareableTask
  | ShareableForm
  | ShareableFormTemplate
  | ShareableExternalLink
  | ShareableWorkflowDefinition
  | ShareableProjectTemplate
  | ShareableDocumentTemplate;

export const isOrganizationResourceType = (resource: ShareableResource): boolean => {
  const map: Record<ShareableResourceType, boolean> = {
    [ShareableResourceType.Document]: false,
    [ShareableResourceType.ExternalLink]: false,
    [ShareableResourceType.Folder]: false,
    [ShareableResourceType.Form]: false,
    [ShareableResourceType.FormTemplate]: true,
    [ShareableResourceType.Task]: false,
    [ShareableResourceType.WorkflowDefinition]: resource.resourceType === ShareableResourceType.WorkflowDefinition && resource.projectId == null,
    [ShareableResourceType.ProjectTemplate]: true,
    [ShareableResourceType.DocumentTemplate]: true,
  };

  return map[resource.resourceType];
};

export const permissionRoleSortOrder: Record<PermissionType, number> = {
  NotAllowed: 0,
  Allowed: 1,
  LimitedAccess: 2,
  View: 3,
  ViewAll: 4,
  Contributor: 5,
  Manage: 6,
};

export type PermissionType = Omit<PermissionsGrouping, '__typename'>[keyof Omit<PermissionsGrouping, '__typename'>];