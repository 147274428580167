import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useAddProjectWorkflowMutation, useProjectWorkflowsQuery, useWorkflowTemplatesQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { WorkflowsTable } from '../components/WorkflowsTable';

export const ProjectWorkflowTemplatesPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const navigate = useNavigate();

  const { projectId } = useCurrentProject();

  const { mutate: createFormTemplate } = useAddProjectWorkflowMutation();

  const handleCreateNewTemplete = () => {
    createFormTemplate({
      input: {
        name: formatMessage({ id: 'New workflow' }),
        projectId: projectId
      }
    }, {
      onSuccess: d => {
        navigate(`/${getTenantIdentifier()}/projects/${projectId}/workflows/templates/edit/${d.addProjectWorkflow.workflowDefinition?.id}`);
        invalidateQuery(useWorkflowTemplatesQuery);
      }
    });
  };

  const { data: workflows, isFetching } = useProjectWorkflowsQuery({ projectId }, { select: d => d.projectWorkflows });

  return (
    <>

      <PageContainer
        title={formatMessage({ id: 'Workflow templates' })}
        action={(
          <ResponsiveButton icon={<Add />} variant='contained' color='primary' onClick={handleCreateNewTemplete}>
            {formatMessage({ id: 'Add template' })}
          </ResponsiveButton>
        )}
      >
        <WorkflowsTable
          workflows={workflows ?? []}
          isFetching={isFetching}
          navigateOnClickLocation={w => `/${getTenantIdentifier()}/projects/${projectId}/workflows/templates/edit/${w.id}`}
        />
      </PageContainer>
    </>
  );
};