import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { ContentCopy, Delete, Draw, Share } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ShareableResourceType, WorkflowDefinitionFragmentFragment, useDeleteWorkflowTemplateMutation, useDuplicateWorkflowDefinitionMutation, useGetProjectQuery, useProjectWorkflowsQuery, useWorkflowTemplatesQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface Props {
  workflow: WorkflowDefinitionFragmentFragment;
  onPreviewClick?: () => void;
}

export const WorkflowActions: React.FC<Props> = ({ workflow }) => {
  const { formatMessage } = useIntl();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const location = useLocation();
  const { me } = useGetMe();

  const editPath = projectId ? `/${getTenantIdentifier()}/projects/${projectId}/workflows/templates/edit/${workflow.id}` : `/${getTenantIdentifier()}/workflows/edit/${workflow.id}`;
  const isEditing = location.pathname.startsWith(editPath);

  const { data: project } = useGetProjectQuery({ id: Number(projectId) }, { enabled: projectId != null, select: p => p.project });
  const canEdit = workflow.createdBy?.id === me?.id;

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);

  const [permissionsDialogOpen, setPermissionsDialogDialogOpen] = useState(false);

  const { mutate: deleteWorkflowTemplate, isLoading } = useDeleteWorkflowTemplateMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Workflow deleted successfully' }));
      invalidateQuery(useWorkflowTemplatesQuery);
      invalidateQuery(useProjectWorkflowsQuery);
      setConfirmRemoveDialogOpen(false);
      if (isEditing) {
        navigate(projectId ? `/${getTenantIdentifier()}/projects/${projectId}/workflows` : `/${getTenantIdentifier()}/workflows`, { replace: true });
      }
    }
  });

  const { mutate: duplicateWorkflow } = useDuplicateWorkflowDefinitionMutation({
    onSuccess: r => {
      notifySuccess(formatMessage({ id: 'Workflow duplicated successfully' }));
      invalidateQuery(useWorkflowTemplatesQuery);
      invalidateQuery(useProjectWorkflowsQuery);
      navigate(`/${getTenantIdentifier()}/workflows/edit/${r.duplicateWorkflowDefinition.workflowDefinition?.id}`);
    }
  });

  const onDuplicateClicked = () => {
    duplicateWorkflow({
      input: {
        workflowId: workflow.id
      }
    });
  };

  const onDeleteConfirm = () => {
    deleteWorkflowTemplate({ id: workflow.id });
  };

  if (!canEdit) {
    return null;
  }

  return <>
    <ActionMenu>
      {(projectId == null || project?.isTemplate == false) && (
        <MenuItem onClick={() => setPermissionsDialogDialogOpen(true)}>
          <ListItemIcon>
            <Share />
          </ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Share' })} />
        </MenuItem>
      )}

      {(projectId == null) && (
        <MenuItem onClick={onDuplicateClicked}>
          <ListItemIcon>
            <ContentCopy />
          </ListItemIcon>
          <ListItemText primary={formatMessage({ id: 'Duplicate' })} />
        </MenuItem>
      )}

      {!isEditing && (
        <MenuItem onClick={() => navigate(editPath)}>
          <ListItemIcon>
            <Draw />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'Edit' })}
          />
        </MenuItem>
      )}

      <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({ id: 'Delete' })}
        />
      </MenuItem>
    </ActionMenu>

    <PermissionsModal
      open={permissionsDialogOpen}
      onClose={() => setPermissionsDialogDialogOpen(false)}
      resource={{
        resourceType: ShareableResourceType.WorkflowDefinition,
        ...workflow
      }}
    />

    <ConfirmDialog
      open={confirmRemoveDialogOpen}
      title={formatMessage({ id: 'Delete workflow template' })}
      content={formatMessage({ id: 'This workflow template will be deleted. Workflows started with this template will not be deleted. Are you sure?' })}
      confirmText={formatMessage({ id: 'Delete' })}
      confirmColor='error'
      onCancel={() => setConfirmRemoveDialogOpen(false)}
      onConfirm={onDeleteConfirm}
      loading={isLoading}
    />
  </>;
};