import { PageSection } from '@components/PageSection/PageSection';
import { FileUpload } from '@mui/icons-material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link as MuiLink } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { PageSectionSkeleton } from '../../../../components/Skeletons/PageSectionSkeleton';
import { DomainEventType, RecentProjectEventsQuery, useRecentProjectEventsQuery } from '../../../../gql';
import { getTenantIdentifier } from '../../../../utils/getTenantIdentifier';
import { useGetMe } from '../../../../utils/useGetMe';
import { getFileNameWithoutExtension } from '../../../folders/utils/folderUtils';
import { RecentEventsTimeline } from '../RecentEvents/RecentEventsTimeline';
import { RecentEvent, eventTimelineMessageByTypeSecondPersonVerb, eventTimelineMessageByTypeThirdPersonVerb, iconByEventType } from '../RecentEvents/types';

type LabeledLink = {
  label: string,
  url: string;
};

type RecentProjectEventsQueryItem = Pick<RecentProjectEventsQuery, 'projectEvents'>['projectEvents'][0];

const urlBuilders: Record<DomainEventType, (event: RecentProjectEventsQueryItem) => LabeledLink> = {
  DocumentSigned: e => ({ label: getFileNameWithoutExtension(e.document?.fileName ?? ''), url: `/${getTenantIdentifier()}/projects/${e.projectId}/documents/${e.document?.folderEntry?.folder?.path ?? ''}` }),
  FileDownloaded: e => ({ label: getFileNameWithoutExtension(e.document?.fileName ?? ''), url: `/${getTenantIdentifier()}/projects/${e.projectId}/documents/${e.document?.folderEntry?.folder?.path ?? ''}` }),
  FileUploaded: e => ({ label: getFileNameWithoutExtension(e.document?.fileName ?? ''), url: `/${getTenantIdentifier()}/projects/${e.projectId}/documents/${e.document?.folderEntry?.folder?.path ?? ''}` }),
  FormFilled: e => ({
    label: e.form?.formDefinition?.name ?? e.formSubmission?.formDefinition?.name ?? '',
    url: e.form ? `/${getTenantIdentifier()}/projects/${e.projectId}/form/${e.form?.id}/${e.formSubmissionId}`
      : `/${getTenantIdentifier()}/projects/${e.projectId}/forms/submissions/${e.formSubmissionId}`
  }),
  WorkflowActionSubmitted: e => ({ label: e.workflowAction?.name ?? '', url: `/${getTenantIdentifier()}/projects/${e.projectId}/workflows/instances/${e.workflowInstanceId}` }),
  MeetingRequested: e => ({ label: e.meeting?.name ?? '', url: `/${getTenantIdentifier()}/projects/${e.projectId}/meetings/requests/${e.meetingId}` }),
  MeetingAccepted: e => ({ label: e.meeting?.name ?? '', url: `/${getTenantIdentifier()}/projects/${e.projectId}/meetings/requests/${e.meetingId}` }),
  MeetingDeclined: e => ({ label: e.meeting?.name ?? '', url: `/${getTenantIdentifier()}/projects/${e.projectId}/meetings/requests/${e.meetingId}` }),

};

export const RecentActivitySection = () => {
  const { formatMessage } = useIntl();

  const { projectId } = useParams();

  const { data: events, isLoading } = useRecentProjectEventsQuery({ projectId: Number(projectId) });
  const { me } = useGetMe();

  const projectEventToRecentEvent = (projectEvent: RecentProjectEventsQueryItem): RecentEvent => {
    const link = urlBuilders[projectEvent.eventType](projectEvent);

    return ({
      eventDate: projectEvent.eventDate,
      eventText: projectEvent.eventTriggeredById === me?.id
        ? formatMessage(eventTimelineMessageByTypeSecondPersonVerb[projectEvent.eventType], {
          link: <MuiLink display={'inline-block'} sx={{ textTransform: 'lowercase' }} component={Link} to={link.url}>{link.label}</MuiLink>
        })
        : formatMessage(eventTimelineMessageByTypeThirdPersonVerb[projectEvent.eventType], {
          user: <b>{projectEvent.eventTriggeredBy?.fullName ?? ''}</b>,
          link: <MuiLink display={'inline-block'} sx={{ textTransform: 'lowercase' }} component={Link} to={link.url}>{link.label}</MuiLink>
        }),
      icon: iconByEventType[projectEvent.eventType]
    });
  };

  function groupUploadsByUserFolderAndHour(dailyEventsByType: Pick<RecentProjectEventsQuery, 'projectEvents'>['projectEvents']) {
    return _(dailyEventsByType)
      .groupBy(p => dayjs(p.eventDate).startOf('hour').toISOString() + (p.document?.folderEntry?.folder?.id ?? '') + (p.eventTriggeredBy?.fullName ?? ''))
      .map((events): RecentEvent => {
        const folder = events[0].document?.folderEntry?.folder;
        const folderUrl = `/${getTenantIdentifier()}/projects/${projectId}/documents/${folder?.path ?? ''}`;
        const folderName = folder?.name ?? formatMessage({ id: 'Documents' });
        return events.length > 1 ?
          ({
            eventDate: dayjs(events[0].eventDate).startOf('hour').toISOString(),
            eventText: events[0].eventTriggeredById === me?.id
              ? formatMessage({ id: 'You uploaded {count} files in {link}' }, {
                count: events.length,
                link: <MuiLink display={'inline-block'} sx={{ textTransform: 'lowercase' }} component={Link} to={folderUrl}>{folderName}</MuiLink>
              })
              : formatMessage({ id: '{user} uploaded {count} files in {link}' }, {
                user: <b>{events[0].eventTriggeredBy?.fullName}</b>,
                count: events.length,
                link: <MuiLink display={'inline-block'} sx={{ textTransform: 'lowercase' }} component={Link} to={folderUrl}>{folderName}</MuiLink>
              }),

            icon: <FileUpload color='action' />,
          })
          :
          projectEventToRecentEvent(events[0]);
      })
      .value();
  }

  const recentEvents = _(events?.projectEvents ?? [])
    .groupBy(p => dayjs(p.eventDate).startOf('day'))
    .flatMap((events) => _(events)
      .groupBy(p => p.eventType)
      .flatMap((dailyEventsByType) => {
        switch (dailyEventsByType[0].eventType) {
          case DomainEventType.FileUploaded:
            return groupUploadsByUserFolderAndHour(dailyEventsByType);
          default:
            return dailyEventsByType.map(projectEventToRecentEvent);
        }
      })
      .orderBy(p => p.eventDate, 'desc')
      .value())
    .value();

  if (isLoading) {
    return (<PageSectionSkeleton icon={<NotificationsIcon />} title={formatMessage({ id: 'Recent Activity' })} />);
  }

  return <>
    <PageSection
      icon={<NotificationsIcon />}
      title={formatMessage({ id: 'Recent Activity' })}
      emptyState={formatMessage({ id: 'There are no recent activities in the project.' })}
    >
      {recentEvents.length > 0 && (
        <RecentEventsTimeline recentEvents={recentEvents} />
      )}
    </PageSection>
  </>;
};

