import { isProjectNavigationMenuCollapsedState, isProjectNavigationMenuOpenState } from '@modules/projects/atoms';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { CalendarMonth, Checklist, EditCalendar, Folder, Group, Home, Paid, Person, Savings, Schedule, Settings, ShoppingCart, TaskAlt, Timeline, Visibility, Web } from '@mui/icons-material';
import SchemaIcon from '@mui/icons-material/Schema';
import { Divider, Drawer, DrawerProps, Stack, Toolbar, styled } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useResponsive } from '@utils/useResponsive';
import { useFeatureFlagsQuery, useGetProjectsQuery, useMeetingTypesQuery } from 'gql/index';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue } from 'recoil';
import { projectCollapsedMenuWidth, projectExpandedMenuWidth } from '.';
import { ProjectNavigationMenuCollapser } from './ProjectNavigationMenuCollapser';
import { ProjectNavigationMenuLink } from './ProjectNavigationMenuLink';
import { ProjectNavigationMenuTabGroup } from './ProjectNavigationMenuTabGroup';
type CollapsibleDrawerProps =
  & DrawerProps
  & { collapsed: boolean, isMobile: boolean; };

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'collapsed' && prop !== 'isMobile'
})<CollapsibleDrawerProps>(({ theme, collapsed, isMobile }) => ({
  flexShrink: 0,
  zIndex: theme.zIndex.appBar - 1,
  '& .MuiDrawer-paper': {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: collapsed ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    width: collapsed && !isMobile ? `${projectCollapsedMenuWidth}px` : `${projectExpandedMenuWidth}px`,
    borderRight: 'none',
    boxShadow: theme.shadows[4],
    boxSizing: 'border-box',
  },
  '& .collapsed h6': {
    visibility: 'hidden'
  },
  '& nav': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }
}));

export const ProjectNavigationMenu: React.FC = () => {
  const { isMobile } = useResponsive();

  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useRecoilState(isProjectNavigationMenuOpenState);
  const isNavigationMenuCollapsedRecoilValue = useRecoilValue(isProjectNavigationMenuCollapsedState);

  const isNavigationMenuCollapsed = isMobile ? false : isNavigationMenuCollapsedRecoilValue;

  const { formatMessage } = useIntl();
  const { projectId, canManageProject, canViewBudgetAndExpenses, isTemplate, hideBudgetFeature, canManageWorkflows } = useCurrentProject();
  const { canViewProjectsList, isExternalUser } = useGetMe();
  const { data: featureFlags } = useFeatureFlagsQuery({}, { select: p => p.featureFlags, staleTime: 300000 });
  const { data: meetingTypes } = useMeetingTypesQuery({ projectId }, { select: p => p.meetingTypes });
  const projectHomeUrl = `/${getTenantIdentifier()}/projects/${projectId}`;
  const prefixWithTenant = (path: string) => `${projectHomeUrl}/${path}`;


  const { data: projects } = useGetProjectsQuery({
    filter:
    {
      and: [
        { isArchived: { eq: false } }
      ]
    }
  }, { select: d => d.projects });


  return (
    <StyledDrawer
      open={isMobile ? isNavigationMenuOpen : true}
      onClose={() => setIsNavigationMenuOpen(false)}
      collapsed={isNavigationMenuCollapsed}
      isMobile={isMobile}
      anchor="left"
      variant={isMobile ? 'temporary' : 'permanent'}
      ModalProps={{ keepMounted: true }}
    >
      <Toolbar sx={{ marginBottom: isMobile ? 8 : 3 }} />

      <Stack component="nav" className={isNavigationMenuCollapsed ? 'collapsed' : ''} whiteSpace='nowrap'>
        <Stack flexGrow={1}>
          {!canViewProjectsList && projects && projects.length > 1 && <>
            <ProjectNavigationMenuTabGroup
              mainEntry={{
                icon: <Web />,
                title: formatMessage({ id: 'Projects' }),
                disabled: true,
                to: prefixWithTenant('home')
              }}
              subEntries={projects?.map(p => ({
                title: p.name,
                isActiveByParent: `projects/${p.id}/`,
                to: `/${getTenantIdentifier()}/projects/${p.id}/home`
              })) ?? []}
            />

            <Divider sx={{ pt: 1, mb: 2 }} />
          </>}

          <ProjectNavigationMenuLink icon={<Home />} title={formatMessage({ id: 'Home' })} to={prefixWithTenant('home')} />

          {hideBudgetFeature != null && !hideBudgetFeature && canViewBudgetAndExpenses && (
            <ProjectNavigationMenuTabGroup
              mainEntry={{
                icon: <Paid />,
                title: formatMessage({ id: 'Budget / Expenses' }),
                to: prefixWithTenant('budget/budget'),
                isActiveByParent: 'budget',
              }}
              subEntries={[
                {
                  icon: <Savings />,
                  title: formatMessage({ id: 'Budget' }),
                  to: prefixWithTenant('budget/budget'),
                },
                !isTemplate && {
                  icon: <ShoppingCart />,
                  title: formatMessage({ id: 'Expenses' }),
                  to: prefixWithTenant('budget/expenses'),
                }
              ]}
            />
          )}

          <ProjectNavigationMenuLink icon={<TaskAlt />} title={formatMessage({ id: 'Tasks' })} to={prefixWithTenant('tasks')} />

          <ProjectNavigationMenuLink icon={<Folder />} title={formatMessage({ id: 'Documents' })} to={prefixWithTenant('documents')} />

          {(canManageProject || isTemplate) && (
            <ProjectNavigationMenuTabGroup
              mainEntry={{
                icon: <CalendarMonth />,
                title: formatMessage({ id: 'Meetings' }),
                to: prefixWithTenant('meetings/requests'),
                isActiveByParent: 'meetings',
              }}
              subEntries={[
                !isTemplate && {
                  icon: <Schedule />,
                  title: formatMessage({ id: 'Meeting requests' }),
                  to: prefixWithTenant('meetings/requests'),
                }, {
                  icon: <EditCalendar />,
                  title: formatMessage({ id: 'Meeting Types' }),
                  to: prefixWithTenant('meetings/types'),
                }
              ]}
            />
          )}

          {!canManageProject && !isTemplate && (!isExternalUser || ((meetingTypes ?? []).length > 0)) && (
            <ProjectNavigationMenuLink icon={<CalendarMonth />} title={formatMessage({ id: 'Meeting requests' })} to={prefixWithTenant('meetings/requests')} />
          )}


          <ProjectNavigationMenuLink icon={<Checklist />} title={formatMessage({ id: 'Forms' })} to={prefixWithTenant('forms')} />

          {!canManageWorkflows && featureFlags?.workflowsEnabled && !isTemplate && (
            <ProjectNavigationMenuLink icon={<Timeline />} title={formatMessage({ id: 'Workflows' })} to={prefixWithTenant('workflows')} />
          )}

          {canManageWorkflows && featureFlags?.workflowsEnabled && !isTemplate && (
            <ProjectNavigationMenuTabGroup
              mainEntry={{
                icon: <Timeline />,
                title: formatMessage({ id: 'Workflows' }),
                to: prefixWithTenant('workflows/templates'),
                isActiveByParent: 'workflows',
              }}
              subEntries={[
                !isTemplate && {
                  icon: <Timeline />,
                  title: formatMessage({ id: 'Workflows' }),
                  to: prefixWithTenant('workflows/instances'),
                }, {
                  icon: <SchemaIcon />,
                  title: formatMessage({ id: 'Templates' }),
                  to: prefixWithTenant('workflows/templates'),
                }
              ]}
            />
          )}


          {canManageWorkflows && featureFlags?.workflowsEnabled && isTemplate && (
            <ProjectNavigationMenuLink icon={<Timeline />} title={formatMessage({ id: 'Workflows' })} to={prefixWithTenant('workflows/templates')} />
          )}


          {(canManageProject || isTemplate) && (
            <ProjectNavigationMenuTabGroup
              mainEntry={{
                icon: <Group />,
                title: formatMessage({ id: 'User management' }),
                to: isTemplate ? prefixWithTenant('permissions/groups') : prefixWithTenant('permissions/users'),
                isActiveByParent: 'permissions',
              }}
              subEntries={[
                !isTemplate && {
                  icon: <Person />,
                  title: formatMessage({ id: 'Members' }),
                  to: prefixWithTenant('permissions/users'),
                }, {
                  icon: <Group />,
                  title: formatMessage({ id: 'Groups' }),
                  to: prefixWithTenant('permissions/groups'),
                }, {
                  icon: <Visibility />,
                  title: formatMessage({ id: 'Permissions' }),
                  to: prefixWithTenant('permissions/permissions'),
                }
              ]}
            />
          )}
        </Stack>

        <Stack>
          {canManageProject && <ProjectNavigationMenuLink icon={<Settings />} title={formatMessage({ id: 'Settings' })} to={prefixWithTenant('settings')} />}

          {!isMobile && <>
            <Divider />
            <ProjectNavigationMenuCollapser />
          </>}
        </Stack>
      </Stack>
    </StyledDrawer>
  );
};