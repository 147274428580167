import { PageContainer } from '@components/Layout/PageContainer';
import { ResponsiveButton } from '@components/ResponsiveButton';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Add } from '@mui/icons-material';
import { Stack, Tab, Tabs } from '@mui/material';
import { useWorkflowInstancesQuery, WorkFlowStatus } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { StartWorkflowDialog } from '../components/StartWorkflowDialog/StartWorkflowDialog';
import { WorkflowInstancesTable } from '../components/WorflowInstance/WorkflowInstancesTable';

export const WorkflowsPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const [isAddingWorkflow, setIsAddingWorkflow] = useState(false);
  const { projectId, isArchived, canManageWorkflows } = useCurrentProject();

  const { data: workflows, isFetching: isFetchingInstances } = useWorkflowInstancesQuery({ projectId }, {
    select: d => d.workflowInstances
  });

  const [selectedTab, setSelectedTab] = useState<'active' | 'history'>('active');

  return (
    <PageContainer
      title={formatMessage({ id: 'Workflows' })}
      action={!isArchived && canManageWorkflows && (
        <ResponsiveButton icon={<Add />} variant='contained' onClick={() => setIsAddingWorkflow(true)}>
          {formatMessage({ id: 'Start a workflow' })}
        </ResponsiveButton>
      )}
    >
      <Stack gap={2}>
        {canManageWorkflows && (
          <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
            <Tab label={formatMessage({ id: 'Active' })} value={'active'} />
            <Tab label={formatMessage({ id: 'History' })} value={'history'} />
          </Tabs>
        )}

        {selectedTab === 'active' &&
          <WorkflowInstancesTable workflows={workflows?.filter(w => w.workFlowStatus == WorkFlowStatus.InProgress) ?? []} loading={isFetchingInstances} />
        }

        {selectedTab === 'history' &&
          <WorkflowInstancesTable showEndDate workflows={workflows?.filter(w => w.workFlowStatus !== WorkFlowStatus.InProgress) ?? []} loading={isFetchingInstances} />
        }

      </Stack>

      <StartWorkflowDialog open={isAddingWorkflow} onClose={() => setIsAddingWorkflow(false)} />
    </PageContainer>
  );
};