import { DataTable } from '@components/DataTable';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { Chip, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { WorkflowDefinitionFragmentFragment } from 'gql/index';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { WorkflowActions } from './WorkflowActions';

interface Props {
  workflows: WorkflowDefinitionFragmentFragment[];
  isFetching: boolean;
  navigateOnClickLocation: (w: WorkflowDefinitionFragmentFragment) => string;
}

export const WorkflowsTable: React.FC<Props> = ({ workflows, isFetching, navigateOnClickLocation }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();


  const columns: GridColDef<WorkflowDefinitionFragmentFragment>[] = [
    {
      field: 'name',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Name' }),

      valueGetter: ({ row }) => row.name,
      renderCell: ({ value }) => <Stack sx={{ cursor: 'pointer' }} > {value}</Stack>
    },
    {
      field: 'createdBy',
      flex: 1,
      minWidth: 150,
      headerName: formatMessage({ id: 'Created by' }),
      valueGetter: ({ row }) => row.createdBy?.fullName,
      renderCell: ({ value }) => <Chip avatar={<UserAvatar displayName={value} />} label={value} />
    },
    {
      field: 'actions',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      disableExport: true,
      maxWidth: 64,
      renderCell: ({ row }) => <WorkflowActions workflow={row} />
    }
  ];

  return (
    <DataTable
      disableColumnMenu
      loading={isFetching}
      columns={columns}
      rows={workflows ?? []}
      noSearchResultsMessage={formatMessage({ id: 'No templates found' })}
      noDataMessage={formatMessage({ id: 'No templates have been created yet' })}
      onCellClick={(params) => params.colDef.field === 'name' && navigate(navigateOnClickLocation(params.row))}
    />
  );
};