import { EmptyState, EmptyStateProps } from '@components/EmptyState';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { FormHelperText, List, ListSubheader, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';

type GroupDefinition<GroupID> = {
  id: GroupID;
  name: string;
  defaultCollapsed?: boolean;
};

type Props<T, GroupID> = {
  items: T[];
  getItemGroup: (item: T) => GroupID;
  groups: GroupDefinition<GroupID>[];

  renderItem: (item: T) => ReactNode;
  emptyState?: EmptyStateProps;
  error?: string;
};

const CollapsableList = ({ title, children, defaultCollapsed }: {
  title: string;
  children: ReactNode;
  defaultCollapsed?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed ?? false);

  return (
    <List subheader={(
      <ListSubheader
        onClick={() => setCollapsed(!collapsed)}
        disableGutters
        sx={{
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        <Stack px={2} direction='row' alignItems='center' justifyContent='space-between' sx={{
          ':hover': {
            backgroundColor: t => t.palette.action.hover
          }
        }}>
          {title}
          {collapsed ? <ExpandMore /> : <ExpandLess />}
        </Stack>
      </ListSubheader>
    )}>
      {collapsed ? null : children}
    </List>
  );
};

export const GroupedList = <T, GroupID>({ items, groups: groupDefinitions, renderItem, getItemGroup, emptyState, error }: Props<T, GroupID>) => {
  const groups = groupDefinitions.map(group => ({
    ...group,
    items: items.filter(item => group.id === getItemGroup(item)),
  })).filter(group => group.items.length > 0);

  return (
    <div>
      <List disablePadding sx={{ maxHeight: 450, overflow: 'auto', border: error ? t => `1px solid ${t.palette.error.main}` : undefined }}>
        {groups.length === 0 && emptyState && (
          <EmptyState {...emptyState} />
        )}

        {groups.map(group => (
          <CollapsableList title={group.name} defaultCollapsed={group.defaultCollapsed}>
            {group.items.map(renderItem)}
          </CollapsableList>
        ))}
      </List>

      {error && (
        <FormHelperText error>{error}</FormHelperText>
      )}
    </div>
  );
};