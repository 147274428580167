import { GroupedList } from '@components/Grouped/GroupedList';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Button, CircularProgress, FormControl, ListItemButton, Radio, Stack } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useProjectWorkflowsQuery, useWorkflowTemplatesQuery } from 'gql/index';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { StartWorkflowFormValues } from '../types';


export const SelectWorkflowTemplateStep = () => {
  const { formatMessage } = useIntl();
  const { me } = useGetMe();
  const { projectId } = useCurrentProject();
  const navigate = useNavigate();
  const { setValue, control, register } = useFormContext<StartWorkflowFormValues>();

  const { data: templates, isLoading: isLoadingOrgTemplates } = useWorkflowTemplatesQuery({}, { select: p => p.workflowTemplates });
  const { data: projectTemplates, isLoading: isLoadingProjectTemplates } = useProjectWorkflowsQuery({ projectId: projectId }, { select: p => p.projectWorkflows });
  const isLoading = isLoadingOrgTemplates || isLoadingProjectTemplates;

  const allTemplates = templates?.concat(projectTemplates ?? []);

  const selectedTemplateId = useWatch({ control, name: 'workflowTemplateId' });

  register('workflowTemplateId', { required: formatMessage({ id: 'Please select a workflow template.' }) });

  if (isLoading && templates == null) {
    return (
      <Stack minHeight='300px' height='100%' width='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack>
      <FormControl
        required
        component="fieldset"
        sx={{ m: 0 }}
        variant="standard"
      >
        <GroupedList
          items={allTemplates ?? []}
          groups={[
            { id: 'projectTemplates', name: formatMessage({ id: 'Project templates' }) },
            { id: 'myTemplates', name: formatMessage({ id: 'My templates' }) },
            { id: 'sharedWithMe', name: formatMessage({ id: 'Shared with me' }) },
          ] as const}
          getItemGroup={p => {
            if (p.projectId != null) {
              return 'projectTemplates';
            } else if (p.createdBy?.id === me?.id) {
              return 'myTemplates';
            } else {
              return 'sharedWithMe';
            }
          }}
          renderItem={template => (
            <ListItemButton
              selected={selectedTemplateId === template.id}
              onClick={() => {
                setValue('workflowTemplateId', template.id);
              }}
            >
              <Radio checked={selectedTemplateId === template.id} disableRipple />
              {template.name}
            </ListItemButton>
          )}
          emptyState={{
            title: formatMessage({ id: 'There are no workflow templates.' }),
            callToActionComponent: (
              <Button onClick={() => navigate(`/${getTenantIdentifier()}/workflows`)}>
                {formatMessage({ id: 'Create new workflow' })}
              </Button>
            )
          }}
        />
      </FormControl>
    </Stack>
  );
};