import { AnonymousPageLayout } from '@modules/application/layouts/AnonymousPageLayout';
import { ProtectedProjectRoute } from '@modules/application/layouts/ProtectedProjectRoute';
import { LoginLayout } from '@modules/application/views/LoginLayout';
import { LoginPage } from '@modules/application/views/LoginPage';
import { NotFoundPage } from '@modules/application/views/NotFoundPage';
import { TenantLoginPage } from '@modules/application/views/TenantLoginPage';
import { TrialPage } from '@modules/application/views/TrialPage';
import { BrandingConfigurationPage } from '@modules/branding/views/BrandingConfigurationPage';
import { ProjectBudgetPage } from '@modules/budget/views/ProjectBudgetPage';
import { ProjectExpensesPage } from '@modules/budget/views/ProjectExpensesPage';
import { DocumentTemplatesPage } from '@modules/documentTemplates/views/DocumentTemplatesPage';
import { ProjectDocumentsContextProvider } from '@modules/folders/components/ProjectDocumentsContextProvider';
import { ProjectDocumentsView } from '@modules/folders/views/ProjectDocumentsView';
import { FormPage } from '@modules/forms/views/projectForms/FormPage';
import { FormTemplatePage } from '@modules/forms/views/templates/FormTemplatePage';
import { FormTemplatesPage } from '@modules/forms/views/templates/FormTemplatesPage';
import { MeetingPage } from '@modules/meetings/views/MeetingPage';
import { MeetingTypesPage } from '@modules/meetings/views/MeetingTypesPage';
import { MeetingsPage } from '@modules/meetings/views/MeetingsPage';
import { AnonymousDocumentPage } from '@modules/projectAccessTokens/views/AnonymousDocumentPage';
import { AnonymousFormPage } from '@modules/projectAccessTokens/views/AnonymousFormPage';
import { ProjectSettings } from '@modules/projects/components/ProjectSettings';
import { ProjectHomePageSelector } from '@modules/projects/views/ProjectHomePageSelector';
import { ProjectLayout } from '@modules/projects/views/ProjectLayout';
import { ProjectTemplateHomePage } from '@modules/projects/views/ProjectTemplateHomePage';
import { ProjectsPage } from '@modules/projects/views/ProjectsPage';
import { PermissionsGroupsView } from '@modules/securityGroups/view/PermissionsGroupsView';
import { PermissionsPermissionsView } from '@modules/securityGroups/view/PermissionsPermissionsView';
import { PermissionsUsersView } from '@modules/securityGroups/view/PermissionsUsersView';
import { ProjectTaskPage } from '@modules/tasks/views/ProjectTaskPage';
import { ProjectTasksView } from '@modules/tasks/views/ProjectTasksPage';
import { SwitchTenantsPage } from '@modules/tenants/views/SwitchTenantsPage';
import { TenantsPage } from '@modules/tenants/views/TenantsPage';
import { InternalUsersPage } from '@modules/users/views/InternalUsersPage';
import { EditWorkflowPage } from '@modules/workflow/views/EditWorkflowPage';
import { ProjectWorkflowTemplatesPage } from '@modules/workflow/views/ProjectWorkflowTemplatesPage';
import { WorkflowFormFillerView } from '@modules/workflow/views/WorkflowFormFillerView';
import { WorkflowInstancePage } from '@modules/workflow/views/WorkflowInstancePage';
import { WorkflowTemplatesPage } from '@modules/workflow/views/WorkflowTemplatesPage';
import { WorkflowsPage } from '@modules/workflow/views/WorkflowsPage';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { BuiltInRoles } from './gql';
import { RouterErrorBoundary } from './modules/application/components/RouterErrorBoundary';
import { AuthenticatedPageLayout } from './modules/application/layouts/AuthenticatedPageLayout';
import { CommercialConstructionCalculator } from './modules/budget/components/CommercialConstructionCalculator/views/CommercialConstructionCalculator';
import { FormSubmissionPage } from './modules/forms/views/projectForms/FormSubmissionPage';
import { FormsHomePage } from './modules/forms/views/projectForms/FormsHomePage';
import { GroupMembersView } from './modules/securityGroups/view/GroupMembersView';
import { TenantRootPage } from './modules/tenants/views/TenantRootPage';
import { EmailsUnsubscribe } from './modules/users/views/EmailsUnsubscribe';

const unauthenticatedTenantRoutes: RouteObject = {
  path: ':tenantId',
  element: <AnonymousPageLayout />,
  children: [
    {
      path: 'documents',
      children: [
        { path: ':token', element: <AnonymousDocumentPage /> }
      ]
    },
    {
      path: 'forms',
      children: [
        { path: ':token', element: <AnonymousFormPage /> }
      ]
    },
    {
      path: 'emails-opt-out/:token',
      element: <EmailsUnsubscribe />
    }
  ]
};

const authenticatedTenantRoutes: RouteObject = {
  path: ':tenantId',
  element: (
    <AuthenticatedPageLayout />
  ),
  children: [
    { index: true, element: <TenantRootPage /> },
    { path: 'switch', element: <SwitchTenantsPage /> },
    {
      path: 'projects',
      children: [
        { index: true, element: <ProjectsPage /> },
        {
          path: ':projectId',
          element: <ProjectLayout />,
          children: [
            { index: true, element: <Navigate to='home' /> },
            { path: 'home', element: <ProjectHomePageSelector /> },
            { path: 'templateHome', element: <ProjectTemplateHomePage /> },
            {
              path: 'documents/*',
              element: (
                <ProjectDocumentsContextProvider>
                  <ProjectDocumentsView />
                </ProjectDocumentsContextProvider>
              )
            },
            {
              path: 'tasks',
              children: [
                { index: true, element: <ProjectTasksView /> },
                { path: ':taskId', element: <ProjectTaskPage /> },
              ]
            },
            {
              path: 'forms',
              children: [
                { index: true, element: <FormsHomePage /> },
                {
                  path: 'filledForms',
                  element: (
                    <ProtectedProjectRoute rule={p => !p.isTemplate}>
                      <FormsHomePage />
                    </ProtectedProjectRoute>
                  )
                },
                { path: 'selfServeForms', element: <FormsHomePage /> },
                { path: 'submissions/:submissionId', element: <FormSubmissionPage /> }
              ]
            },
            {
              path: 'budget',
              element: <ProtectedProjectRoute rule={p => p.canViewBudgetAndExpenses} />,
              children: [
                { index: true, element: <Navigate to={'budget'} /> },
                { path: 'budget', element: <ProjectBudgetPage /> },
                {
                  path: 'expenses',
                  element: (
                    <ProtectedProjectRoute rule={p => !p.isTemplate}>
                      <ProjectExpensesPage />
                    </ProtectedProjectRoute>
                  )
                },
                {
                  path: 'calculator/:id',
                  element: <CommercialConstructionCalculator />
                }
              ],
            },
            {
              path: 'workflows',
              children: [
                { index: true, element: <Navigate to={'instances'} replace={true} /> },
                { path: 'templates', element: <ProjectWorkflowTemplatesPage /> },
                { path: 'instances', element: <WorkflowsPage /> },
                { path: 'instances/:workflowId', element: <WorkflowInstancePage /> },
              ]
            },
            {
              path: 'meetings',
              element: <ProtectedProjectRoute rule={p => !p.isTemplate} />,
              children: [
                { index: true, element: <Navigate to={'requests'} replace={true} /> },
                { path: 'requests', element: <MeetingsPage /> },
                { path: 'requests/:meetingId', element: <MeetingPage /> },
              ]
            }
          ]
        },
        {
          path: ':projectId/workflows',
          children: [
            {
              path: 'edit/:workflowId', element: (
                <ProtectedProjectRoute rule={p => p.canManageWorkflows}>
                  <EditWorkflowPage />
                </ProtectedProjectRoute>
              )
            }
          ]
        },
        {
          path: ':projectId',
          children: [
            {
              path: 'form',
              children: [
                { index: true, element: <Navigate to={'../forms'} /> },
                {
                  path: ':formId',
                  children: [
                    { index: true, element: <FormPage /> },
                    { path: ':submissionId', element: <FormSubmissionPage /> },
                  ]
                }
              ]
            },
          ]
        },
        {
          path: ':projectId/workflow/:workflowId',
          children: [
            { index: true, element: <WorkflowInstancePage /> },
            { path: 'action/:actionId/:submissionId', element: <WorkflowFormFillerView /> },
          ]
        }
      ]
    }
  ]
};

const projectAdminRoutes: RouteObject = {
  path: ':tenantId',
  element: (
    <AuthenticatedPageLayout allowedRoles={[BuiltInRoles.TenantAdministrator, BuiltInRoles.ProjectAdministrator, BuiltInRoles.Contributor]} />
  ),
  children: [
    {
      path: 'projects',
      children: [{
        path: ':projectId',
        element: (
          <ProtectedProjectRoute rule={p => p.canManageProject || !!p.isTemplate}>
            <ProjectLayout />
          </ProtectedProjectRoute>
        ),
        children: [
          {
            path: 'permissions', children: [
              {
                path: 'users',
                element: (
                  <ProtectedProjectRoute rule={p => !p.isTemplate}>
                    <PermissionsUsersView />
                  </ProtectedProjectRoute>
                )
              },
              { path: 'permissions', element: <PermissionsPermissionsView /> },
              {
                path: 'groups', children: [
                  { index: true, element: <PermissionsGroupsView /> },
                  { path: ':groupId', element: <GroupMembersView /> }
                ]
              }
            ]
          },
          {
            path: 'meetings',
            children: [
              { path: 'types', element: <MeetingTypesPage /> },
            ]
          },
          {
            path: 'settings',
            element: <ProjectSettings />
          }
        ]
      }]
    },
    {
      path: 'projects/:projectId/workflows/templates',
      children: [
        { path: 'edit/:workflowId', element: <EditWorkflowPage /> }
      ]
    },
    {
      path: 'document-templates',
      children: [
        { index: true, element: <DocumentTemplatesPage /> },
      ]
    },
    {
      path: 'templates',
      children: [
        { index: true, element: <FormTemplatesPage /> },
        { path: 'edit/:templateId', element: <FormTemplatePage /> }
      ]
    },
    {
      path: 'workflows',
      children: [
        { index: true, element: <WorkflowTemplatesPage /> },
        { path: 'edit/:workflowId', element: <EditWorkflowPage /> }
      ]
    }
  ]
};



const tenantAdminRoutes: RouteObject = {
  path: ':tenantId',
  element: (
    <AuthenticatedPageLayout allowedRoles={[BuiltInRoles.TenantAdministrator]} />
  ),
  children: [
    {
      path: 'users',
      children: [
        { index: true, element: <InternalUsersPage /> },
      ]
    },
    {
      path: 'branding',
      children: [
        { index: true, element: <BrandingConfigurationPage /> },
      ]
    },
  ],
};

const multiTenantAdminRoutes: RouteObject = {
  path: ':tenantId',
  element: (
    <AuthenticatedPageLayout allowedRoles={[BuiltInRoles.MultiTenantAdministrator]} />
  ),
  children: [
    {
      path: 'tenants',
      children: [
        { index: true, element: <TenantsPage /> }
      ]
    },
  ]
};

const routes: RouteObject[] = [{
  path: '/',
  element: (
    <Outlet />
  ),
  errorElement: <RouterErrorBoundary />,
  children: [
    {
      element: <LoginLayout />,
      children: [
        { index: true, element: <LoginPage /> },
        { path: 'trial', element: <TrialPage /> }
      ]
    },
    {
      path: ':tenantId/login',
      element: <TenantLoginPage />
    },
    { path: 'error/404', element: <NotFoundPage /> },
    authenticatedTenantRoutes,
    unauthenticatedTenantRoutes,
    projectAdminRoutes,
    tenantAdminRoutes,
    multiTenantAdminRoutes
  ]
}];

export default routes;