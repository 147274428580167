import { FormDrawer } from '@components/Drawers/FormDrawer';
import { UploadZoneRoot } from '@components/FileUpload/UploadZoneRoot';
import { DocumentFileNameAndIcon } from '@modules/documents/components/DocumentFilenameAndIcon';
import { Delete, UploadFile } from '@mui/icons-material';
import { DrawerProps, FormLabel, IconButton, Link, ListItem, Stack, TextField, Typography } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import { DocumentTemplateFragmentFragment, useDocumentTemplatesQuery, useEditDocumentTemplateMutation } from 'gql/index';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDocumentTemplateDropzone } from '../useDocumentTemplateDropzone';

interface FormValues {
  name: string;
  description: string;
}

const defaultValues: FormValues = {
  name: '',
  description: ''
};

interface Props extends DrawerProps {
  template?: DocumentTemplateFragmentFragment;
}

type RequestSender = (name: string, description: string, callback: () => void) => void;

export const DocumentTemplateDrawer: React.FC<Props> = ({ template, ...props }) => {
  const { notEmpty } = useValidationRules();
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();

  const [currentFileName, setCurrentFileName] = useState<string | null>(null);
  const [fileSender, setFileSender] = useState<RequestSender | null>(null);

  const { reset, control, handleSubmit } = useForm<FormValues>({ defaultValues });

  useEffect(() => {
    if (template) {
      reset({
        name: template.name,
        description: template.description ?? ''
      });
      setCurrentFileName(template.fileName ?? '');
      setFileSender(null);
      setIsUploadingFile(false);
    } else {
      reset();
      setCurrentFileName(null);
      setFileSender(null);
      setIsUploadingFile(false);
    }
  }, [reset, props.open, template]);



  const { mutateAsync: editTemplate, isLoading: isEditing } = useEditDocumentTemplateMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Document template updated successfully.' }));
      invalidateQuery(useDocumentTemplatesQuery);
      props.onClose?.({}, 'backdropClick');
      setIsUploadingFile(false);
    }
  });

  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const isLoading = isEditing || isUploadingFile;

  const onSubmit = async (data: FormValues) => {
    if (template) {
      await editTemplate({
        input: {
          id: template.id,
          name: data.name,
          description: data.description
        }
      });
    } else {
      setIsUploadingFile(true);

      fileSender?.(data.name, data.description, () => {
        invalidateQuery(useDocumentTemplatesQuery);
        setIsUploadingFile(false);
        props.onClose?.({}, 'backdropClick');
      });
    }
  };

  const { getInputProps, getRootProps, isDragActive } = useDocumentTemplateDropzone({
    disabled: isLoading,
    onFileUploadReady: (sender, filename) => {
      setCurrentFileName(filename);
      setFileSender(() => sender);
    }
  });

  return (
    <FormDrawer
      {...props}
      onSave={handleSubmit(onSubmit)}
      showFooter
      isSubmitting={isLoading}
      header={template ? formatMessage({ id: 'Edit document template' }) : formatMessage({ id: 'Add document template' })}
    >
      <Stack p={2} gap={2}>
        <Controller
          name='name'
          control={control}
          rules={{ validate: { notEmpty } }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              required
              label={formatMessage({ id: 'Name' })}
              error={!!error}
              helperText={error?.message}
              disabled={isLoading}
            />
          )}
        />

        <Controller
          name='description'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label={formatMessage({ id: 'Description' })}
              error={!!error}
              helperText={error?.message}
              disabled={isLoading}
            />
          )}
        />

        <Stack>
          <FormLabel>{formatMessage({ id: 'Template' })}</FormLabel>
          {currentFileName ? (
            <ListItem disablePadding sx={{ border: t => `1px solid ${t.palette.divider}` }}>
              <DocumentFileNameAndIcon
                fileName={currentFileName}

                actionButton={!isLoading && !template && (
                  <IconButton
                    disabled={isLoading}
                    onClick={() => {
                      setCurrentFileName(null);
                      setFileSender(null);
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
              />
            </ListItem>
          ) : (
            <UploadZoneRoot isDragActive={isDragActive} dropZoneRootProps={getRootProps()}>
              <UploadFile color='primary' />
              <input {...getInputProps()} />
              <Stack alignItems='center' gap={1}>
                <Typography variant='body1' textAlign='center'>
                  <Link href='#'>
                    {formatMessage({ id: 'Click to upload' })}
                  </Link>
                  {' '}{formatMessage({ id: 'or drag and drop' })}
                </Typography>

                <Typography fontWeight={600} textAlign='center'>
                  {formatMessage({ id: 'Only .docx files are supported' })}
                </Typography>

                <Typography variant='body1' color='grey.600'>{formatMessage({ id: '(10MB max)' })}</Typography>
              </Stack>
            </UploadZoneRoot>
          )}
        </Stack>
      </Stack>
    </FormDrawer>
  );
};