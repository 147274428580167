import { GroupedList } from '@components/Grouped/GroupedList';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { ListItemButton, Radio } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useGetProjectsQuery } from 'gql/index';
import { useIntl } from 'react-intl';

type Props = {
  value: number | null,
  onChange: (value: number) => void;
  error?: string;
};

export const ImportProjectTextField = ({ value, onChange, error }: Props) => {
  const { me } = useGetMe();
  const { formatMessage } = useIntl();

  const { projectId } = useCurrentProject();
  const { data: projects } = useGetProjectsQuery(undefined, { select: d => d.projects });

  const isMemberOf = (project: NonNullable<typeof projects>[number]) => project.securityGroups.some(sg => sg.members.some(m => m.id === me?.id));

  return (
    <GroupedList
      error={error}
      items={projects?.filter(p => p.id != projectId) ?? []}
      groups={[
        { id: 'myProjects', name: formatMessage({ id: 'My projects' }) },
        { id: 'templates', name: formatMessage({ id: 'Project templates' }) },
        { id: 'sharedWithMe', name: formatMessage({ id: 'Shared with me' }), defaultCollapsed: true },
        { id: 'archived', name: formatMessage({ id: 'Archived' }), defaultCollapsed: true }
      ] as const}
      getItemGroup={p => {
        if (isMemberOf(p) && !p.isTemplate && !p.isArchived) {
          return 'myProjects';
        } else if (isMemberOf(p) && p.isTemplate && !p.isArchived) {
          return 'templates';
        } else if (!p.isArchived) {
          return 'sharedWithMe';
        }
        return 'archived';
      }}
      renderItem={project => (
        <ListItemButton
          selected={value === project.id}
          onClick={() => onChange(project.id)}
        >
          <Radio checked={value === project.id} disableRipple />
          {project.name}
        </ListItemButton>
      )}
    />
  );
};