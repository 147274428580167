import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ShareableResourceType, useDocumentPermissionsQuery, useDocumentTemplatePermissionsQuery, useExternalLinkPermissionsQuery, useFolderPermissionsQuery, useFolderPropertiesQuery, useGetDocumentQuery, useProjectFormPermissionsQuery, useProjectTaskPermissionsQuery, useProjectTaskQuery, useProjectTemplatePermissionsQuery, useTemplatePermissionsQuery, useWorkflowTemplatePermissionsQuery } from 'gql/index';
import { useParams } from 'react-router-dom';
import { ShareableResource } from './types';

export const useShareableResourceInvalidator = (resource: ShareableResource) => {
  const { projectId: projectIdString } = useParams();
  const projectId = Number(projectIdString);
  const invalidateQuery = useQueryInvalidator();

  const invalidator: Record<ShareableResourceType, () => void> = {
    Folder: () => {
      invalidateQuery(useFolderPropertiesQuery, { folderId: resource.id });
      invalidateQuery(useFolderPermissionsQuery, { folderId: resource.id, projectId });
    },
    Document: () => {
      invalidateQuery(useGetDocumentQuery, { id: resource.id });
      invalidateQuery(useDocumentPermissionsQuery, { documentId: resource.id, projectId });
    },
    Task: () => {
      invalidateQuery(useProjectTaskQuery, { taskId: resource.id });
      invalidateQuery(useProjectTaskPermissionsQuery, { taskId: resource.id });
    },
    Form: () => {
      invalidateQuery(useProjectFormPermissionsQuery, { formId: resource.id, projectId });
    },
    FormTemplate: () => {
      invalidateQuery(useTemplatePermissionsQuery, { formTemplateId: resource.id });
    },
    ExternalLink: () => {
      invalidateQuery(useExternalLinkPermissionsQuery, { linkId: resource.id });
    },
    WorkflowDefinition: () => {
      invalidateQuery(useWorkflowTemplatePermissionsQuery, { templateId: resource.id });
    },
    ProjectTemplate: () => {
      invalidateQuery(useProjectTemplatePermissionsQuery, { templateId: resource.id });
    },
    DocumentTemplate: () => {
      invalidateQuery(useDocumentTemplatePermissionsQuery, { id: resource.id });
    }
  };

  return invalidator[resource.resourceType];
};